/* CUSTOM VARIABLES */
:root {
    --font-lg: 2rem;
    --font-md: 1.8rem;
    --font-sm: 1.7rem;
    --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.rv_container {
    width: 1270px;
    display: flex;
    justify-content: center;
    margin: 0rem auto;
    gap: 3rem;
    /* padding: 0 1rem; */
    margin-top: 4rem;
}

.rv_main {
    flex: 3.5;
}

/* END OF GLOBAL STYLE */

.rv_main__title {
    color: var(--color-primary-red);
}

.rv_main__date {
    font-size: var(--font-sm);
}

.rv_side__logo {
    margin-top: 3rem;
    margin-right: 8vw;
}


.rv_middle__top_section {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
}

.rv_middle__top_section input {
    height: 2rem;
    padding: 1.8rem 3rem;
    width: 100%;
    font-size: 1.5rem;
    border-radius: .5rem;
}

.rv_search_bar {
    flex: 1;
    position: relative;
}

.rv_search_bar__search_icon img {
    width: 1.4rem;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
}

.rv_search_bar__calendar_icon svg {
    width: 1.4rem;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
}


/* add button style */

.rv_add_button svg {
    fill: #ffffff;
    margin-left: 1rem;
}

.rv_add_button {
    font-size: var(--font-xs);
    color: #ffffff;
    background: #951829;
    border: none;
    padding: .1rem 1.4rem;
    border-radius: .5rem;
    /* width: 4rem; */
    cursor: pointer;
    transition: all .2s;
    font-family: 'beIN Normal';
    min-height: 3.5rem;
    min-width: 8rem;
}

.rv_add_button:hover {
    opacity: .8;
}

/* end of add button style */


/* table style */

.rv_table table {
    width: 100%;
    text-align: right;
    border-collapse: collapse;
}

.rv_table table thead tr {
    height: 4.2rem;
}

.rv_table table tfoot tr {
    height: 3.8rem;
}

.rv_table table tbody tr {
    height: 4.2rem;
    border-bottom: 3rem solid #eeeeee;
}

.rv_table table tr {
    border: 1px solid #eeeeee;
}

.rv_table table td,
.rv_table table th {
    padding-right: 1.5rem;
}

.rv_table table td:nth-child(2),
.rv_table table th:nth-child(2) {
    /* text-align: right; */
}

.rv_table thead {
    color: #B4B4B4;
    font-size: var(--font-xs);
}

.rv_table thead tr {
    color: #B4B4B4;
    font-size: var(--font-xs);
}

.rv_table tbody td {
    font-size: var(--font-sm);
}

.rv_table tbody td:nth-child(1) {
    background-color: var(--color-bkg);
    font-size: var(--font-xs);
}

.rv_table table tbody tr:nth-child(odd) {
    background: #f9f9f9;
}

.rv_table table tbody tr:nth-child(even) {
    background: #fff;
}

.rv_table table tfoot {
    text-align: center;
    background: #f9f9f9;
}


.rv__judgements_table table td:nth-child(1),
.rv__judgements_table table th:nth-child(1) {
    min-width: 12rem;
}

.rv__judgements_table table td:nth-child(5),
.rv__judgements_table table th:nth-child(5) {
    text-align: left;
    direction: ltr;
    transform: translateX(1.5rem);
}

.rv__judgements_table table tbody td:nth-child(5) {
    display: flex;
    align-items: center;
    /* padding-right: 2rem; */
    gap: 1rem;
    transform: translateX(1rem);
}

.rv__judgements_table table tbody td:nth-child(5) svg {
    height: 20px;
}

.rv__judgements_table table tbody td:nth-child(5) div {
    cursor: pointer;
    /* transform: translateY(12.5%); */
}

/* FIX THIS */
.rv__judgements_table table thead th:nth-child(4),
.rv__judgements_table table tbody td:nth-child(4) {
    /* padding-right: 8rem; */
}

.rv__judgements_table tbody td:nth-child(1) {
    position: relative;
    min-width: 10rem;
}

.rv_td_span_day {
    position: absolute;
    font-size: 1.4rem;
    transform: translateY(-50%);
    color: #b4b4b4;
    top: 0;
}

.rv_td_span_date {
    font-weight: bold;
}

.rv_td_span_time {
    font-size: var(--font-xs);
    position: absolute;
    color: #CA2946;
    min-width: 5rem;
    line-height: 1;
    left: .5rem;
    top: 0;
    transform: translateY(75%);
    text-align: left;
}

/* end table style */



.rv_middle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
}

.rv_middle__col-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 3;
    position: relative;
}

.rv_middle__col-left {
    flex: 1;
}

.mobile_button_section {
    /* width: 100%; */
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    padding-left: 2rem;
    font-size: var(--font-lg);
}

.table_mobile_date {
    display: none;
}

/* calendar style */
/* original */
.calendar_rv__month_bar,
.calendar_rv__weekdays,
.calendar_rv__days {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: var(--font-xs);
}

.calendar_rv__month_bar {
    color: white;
    background: var(--color-primary-red);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    height: 3.2rem;
    align-items: center;
}

.calendar_rv__month_bar svg {
    cursor: pointer;
}

.calendar_rv__weekdays {
    font-size: 1.2rem;
    text-align: center;
    margin: 1.2rem 0;
}

.calendar_rv__weekdays,
.calendar_rv__days {
    direction: ltr;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.calendar_rv__days {
    background: white;
    height: calc(6*4.5ch);
}

/* Style for each day */
.calendar_rv__days button {
    outline: none;
    border: none;
    position: relative;
    border: 0;
    width: 4.5ch;
    height: 4.5ch;
    background-color: white;
    color: var(--blue-grey-600);
    z-index: 100;
}

.calendar_rv__days button:hover,
.calendar_rv__days button:focus {
    outline: none;
    background-color: rgba(255, 0, 0, 0.466);
    color: white;
}

.calendar_rv__days button:active,
.calendar_rv__days button.is-selected {
    color: white;
    background: var(--color-primary-red);
}

.calendar_rv_marked_day {}

/* Positioning the first day on a Friday */
/* .calendar_rv__days button:nth-child(1) {
    grid-column: 4;
  } */

/* end of calendar style */
/* end original calendar style */

.rv_form__t,
.rv_form__cal {
    position: relative;
}

#rv_calendar {
    position: absolute;
    border: 1px solid #eee;
    padding: 0.5rem;
    z-index: 100;
    right: 0;
    transform: translateY(-.5rem);
}

.rv_calendar {
    display: none;
}

#rv_calendar__month_bar-white {
    background: white;
}

#rv_calendar__weekdays {
    color: #000;
    font-weight: bold;
    background-color: white;
    margin: 0;
    padding: 1rem 0;
}


/* end of calendar style */



/* new date style */
.rv_newDate {
    position: absolute;
    width: 100%;
    background: white;
    padding-right: 4rem;
    padding-left: 4rem;
    padding-top: 6rem;
    padding-bottom: 2rem;
    display: none;
}

.rv_form__sections {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    gap: 7rem;
    font-size: var(--fonts-md);
}

.rv_form__btn_grp {
    display: flex;
    width: 100%;
    justify-content: end;
    padding-left: 2rem;
    margin-top: 5rem;
}

.rv_form__btn_grp button {
    border: none;
    padding: 0 1.8rem;
    font-size: var(--font-xs);
    border-radius: .8rem;
    cursor: pointer;
    margin-right: 1rem;
    font-family: "beIN Normal";
}

.rv_form__btn_grp button {
    display: block;
    /* float: left; */
    margin-left: 1rem;
}

.rv_form__btn_grp .tickSpan {
    transform: translateY(20%);
    height: 15px;
    margin-left: .5rem;
}

.rv_ltSpan {
    /* font-size: var(--font-lg); */
    margin-left: 1.5rem;
}

.rv_form__btn_grp button:last-child {
    color: white;
    background: #63B17D;
}

.rv_form__sections-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1.3;
    gap: 1rem;
    font-size: var(--font-xs);
}

.rv_form__sections-right div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.rv_form__sections-right input,
.rv_form__sections-right select {
    width: 70%;
    height: 2.8rem;
    border: .1rem solid #CBCBCB;
    background: none;
    font-size: var(--font-xs);
    border-radius: .5rem;
}

.rv_form__sections-right input {
    padding: 1rem;
}

.rv_form__sections-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rv_form__sections-left_field {
    flex: 1;
    font-size: var(--font-xs);
}

.rv_form__sections-left_field {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1rem;
    align-items: center;
    justify-content: start;
}

.rv_form__label {
    margin-left: 2rem;
}

.rv_form__sections-left_field svg {
    transform: translateY(25%);
}

.rv_form__sections label,
.rv_form__sections-left_field div:nth-child(1) {
    color: #891423;
}

.rv_form__date_selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: .1rem 1rem;
    /* transform: scale(0); */
}

.rv_form__time_selected {
    color: #2d2d2d;
    margin-right: 1rem;
}

.rv_err_msg {
    color: #FF8000;
}

.rv_time_icon,
.rv_calendar_icon {
    height: 20px;
    cursor: pointer;
}

/* end of new date style */

/******************************/

/* utils */
.rv_fill_path {
    fill: white;
}

.show_block {
    display: block;
}

.show_flex {
    display: flex;
}

.rotate_svg {
    transform: rotate(180deg);
}

.rv_greyed_out {
    background-color: var(--color-bkg) !important;
}

/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {

    /* CUSTOM VARIABLES */
    /* smaller font sizes */
    :root {
        --font-lg: 1.8rem;
        --font-md: 1.7rem;
        --font-sm: 1.6rem;
        --font-xs: 1.5rem;
    }

    /* END OF CUSTOM VARIABLES */
    .rv_container {
        width: 1140px;
        margin-top: 4rem;
    }

    /* size of svg icons in side menu */
    .rv_menu_svg {
        width: 2.7rem;
    }

    /* height of list items in side menu */
    /* .side li a {
        height: 3.8rem;
        margin-bottom: .8rem;
    } */

    .rv_side__logo {
        margin-right: 3vw;
    }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
    .rv_container {
        width: 1000px;
    }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
    .rv_container {
        width: 820px;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
        margin-top: 8rem;
    }

    .rv_middle__col-left {
        display: none;
    }

    .rv_search_bar,
    .rv_top_section__btn_div {
        display: none;
    }

    .rv__judgements_table div {
        margin-left: 1rem;
    }

    .rv__judgements_table td {
        padding-top: .3rem;
        padding-bottom: .3rem;
    }
    .rv__judgements_table td:nth-child(2) {
        position: relative;
    }
    .rv__judgements_table th,
    .rv__judgements_table td:nth-child(1),
    .rv__judgements_table td:nth-child(5) {
        display: none !important;
    }

    .rv_table_location_pin {
        display: block;
    }

    .table_mobile_date {
        font-size: 1.4rem;
        position: absolute;
        top: 0;
        right: 1rem;
        transform: translateY(-2.7rem);
        display: flex;
        flex-direction: row;
    }
    .table_mobile_date span{
        margin-left: 1rem;
    }

    /* newDate style */
    .rv_form__sections {
        flex-direction: column;
    }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
    .rv_container {
        width: 600px;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
    }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
    .rv_container {
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
    }
}