/* GLOBAL STYLE */
.archives_container {
    width: 1200px;
    display: flex;
    justify-content: center;
    margin: 0rem auto;
    gap: 3rem;
    /* padding: 0 1rem; */
    margin-top: 4rem;
}

.archives_main {
    flex: 3.5;
}

/* END OF GLOBAL STYLE */

.archives_main__title {
    color: var(--color-primary-red);
}

.archives_main__date {
    font-size: var(--font-sm);
}

.archives_side__logo {
    margin-top: 3rem;
    margin-right: 8vw;
}

.archives_top_bar__identifier {
    gap: .5rem;
}

.archives_top_bar__pic {
    width: 2.8rem;
    height: 2.8rem;
    background: var(--color-secondary-red);
    border-radius: 50%;
    color: white;
    position: relative;
}

.archives_top_bar__user_initial {
    position: absolute;
    line-height: 0;
    top: 1.4rem;
    left: 1.4rem;
    transform: translate(-50%);
    font-size: 2rem;
}

.archives_middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
    /* height: calc(100vh -30rem); */
    min-height: calc(100% - 218px);
    background-color: #fff;
}

.all_folders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    margin-left: auto;
    margin-right: auto;
}
.single_folder {
    margin: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--font-xs);
}
.single_folder svg {
    height: 8rem;
}
/******************************/
/* utils */
.archives_fill_path {
    fill: white;
}

.show_block {
    display: block;
}

.show_flex {
    display: flex;
}

.rotate_svg {
    transform: rotate(180deg);
}

.hide_scale {
    transform: scale(0);
}
.hide {
    display: none;
}
/* end of utils */
/******************************/


/* Extra Large screen */
@media only screen and (max-width: 1920px) {

    /* CUSTOM VARIABLES */
    /* smaller font sizes */
    :root {
        --font-lg: 1.8rem;
        --font-md: 1.7rem;
        --font-sm: 1.6rem;
        --font-xs: 1.5rem;
    }

    /* END OF CUSTOM VARIABLES */
    .archives_container {
        width: 1140px;
        margin-top: 4rem;
    }

    /* size of svg icons in side menu */
    .archives_menu_svg {
        width: 2.7rem;
    }

    /* height of list items in side menu */
    /* .side li a {
        height: 3.8rem;
        margin-bottom: .8rem;
    } */

    .archives_side__logo {
        margin-right: 3vw;
    }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
    .archives_container {
        width: 1000px;
    }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
    .archives_container {
        width: 820px;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
        margin-top: 9rem;
    }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
    .archives_container {
        width: 600px;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
    }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
    .archives_container {
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
    }
}