body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}
@font-face {
  font-family: 'beIN Normal';
  src: url('./assets/fonts/beIN/beIN_Normal.ttf');
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input-error {
  border: 1px solid rgb(189, 35, 60) !important;
}
.left_section {
  width: 40px;
  display: flex;
  justify-content: center;
  margin-top: 23px;
}

.sub_desc {
  text-align: end;
  width: 24rem;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #eceef5;
  opacity: 0.9;
}
.select_container_item {
  margin-top: -7%;
  height: 150px;
  margin-left: -7%;
  width: 321px;
  display: flex;
  justify-content: flex-end;
}

.right_section {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.toast_title {
  text-align: end;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 4px;
  margin-bottom: 6px;
}

.Toastify__close-button {
  margin-top: -8px;
  margin-bottom: 6px;
}

.toast_succ {
  background: #1dd2c1;
}

.toast_error {
  background: rgb(202, 41, 70);
}

.toast_warning {
  background: #f6933e;
}

.toast_info {
  background: #819cff;
}

.Toastify__toast-container {
  margin-bottom: 0 !important;
}

.Toastify__toast {
  height: 131px !important;
  border-radius: 5px !important;
}

/*DATE PICKER */
.react-datepicker__current-month {
  height: 2rem !important;
  width: 21rem !important;
  background: var(--color-primary-red);
  color: white;
  font-size: 14px;
}
.react-datepicker__header {
  background: var(--color-primary-red) !important;
  border: 0 !important;
}
.react-datepicker__day-names {
  background: #eeeeee;
}
.react-datepicker__day-name {
  width: 2.7rem !important;
  height: 3rem;
  background: #eeeeee;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: var(--font-xs);
}
.react-datepicker__month-container {
  height: 28rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.7rem !important;
  height: 3rem;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 12px;
  padding: 3%;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white !important;
  font-size: 13px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: white !important;
  background: var(--color-primary-red) !important;
}
.react-datepicker__day:hover {
  color: white !important;
  background: rgb(189, 35, 60) !important;
}
.react-datepicker__header__dropdown {
  height: 1rem;
}
.react-datepicker {
  border: 0 !important;
}
.home_container li {
  border-radius: 50px;
}
.home_table thead {
  background-color: #e3e3e3;
}
.MuiFormControl-root input {
  border: none !important;
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #1a1c1d !important;
  width: 100% !important;
}
.MuiFormControl-root {
  border-bottom: 0px solid #ffffff !important;
}
.MuiFormControl-root span {
  color: #8181a5;
}
.MuiAutocomplete-popper {
  z-index: 9999;
}

.MuiInputLabel-formControl {
  left: auto !important;
  font-size: 15px;
}
.MuiFormLabel-root {
  font-size: 15px !important;
}
.MuiAutocomplete-root {
  height: 2.8rem !important;
}
.MuiInputLabel-animated {
  right: 2.8rem !important;
}

.home_side {
  width: 17%;
}
/*li > a {
  font-size: 15px;
  font-weight: bold;
}*/
.lawsuit_form__label,
.lawsuit_form__sections label,
.lawsuit_form__sections-left div:first-child {
  width: 11%;
}
.MuiInput-underline.Mui-error:after {
  border: 1px solid rgb(189, 35, 60) !important;
}

.clients_col-right {
  flex: 1.4 1;
}
.rdv_col-right {
  flex: -0.6 1 !important;
}
.clients_table {
  flex: 2 1 !important;
}
.clients_field_title {
  font-weight: bold;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgb(189, 35, 60) !important;
}
.clients_table_section__btn_grp {
  flex: 0.5 1 !important;
}

.logo-auth {
  width: 55%;
  margin-top: 15%;
  margin-right: 20%;
  margin-bottom: 3%;
}
.home_side__logo {
  margin-right: 0vw !important;
}

.img-screen {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.react-datepicker__time-list-item,
.MuiAutocomplete-option {
  font-size: 1.3rem;
}
.clients_form__sections-left div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
}

.clients_form__sections-left input,
.clients_form__sections-left select {
  width: 70%;
  height: 2.8rem;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
  padding: 1rem;
}

.clients_form__sections-left {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.3;
  gap: 1rem;
  font-size: var(--font-xs);
}
.clients_form__sections-left textarea {
  width: 70%;
  height: 10rem;
  outline: none;
  resize: none;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
  padding: 1rem;
}
.clients_form__sections {
  gap: 3rem !important;
}
.clients_form__sections-right {
  flex: 1 !important;
}

tspan {
  white-space: pre;
}

.shp0 {
  fill: #000000;
}

.shp1 {
  fill: #f12424;
}

.txt2 {
  font-size: 10px;
  line-height: 28px;
  fill: #ffffff;
  font-family: 'be IN Normal';
  text-anchor: center;
}
@font-face {
  font-family: 'beIN Normal';
  src: url('./assets/fonts/beIN/beIN_Normal.ttf');
}

.noti_dropdown__content > a:hover {
  background-color: #e9eaea;
}

.msg_snippet {
  font-size: 0.9rem !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 25px !important;
}
.MuiTypography-root,
.MuiAlert-icon {
  width: 20% !important;
}

.MuiAlert-message > span {
  width: 80% !important;
  font-size: 14px;
}
.MuiAlert-message > div {
  font-size: 14px;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: #7b1725 !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #7b1725 !important;
}
.react-datepicker-time__header {
  font-size: 0 !important;
}

.react-datepicker-time__header::after {
  content: 'التوقيت' !important;
  font-size: 1.5rem;
}

.sidenav {
  width: 20%;
  position: fixed;
  padding-right: 3%;
  z-index: 1;
  right: 10px;
  overflow-x: hidden;
}

.MuiAppBar-colorPrimary {
  background-color: #a8192b !important;
}
.MuiTab-wrapper {
  font-size: 15px !important;
  font-weight: bold !important;
}

.MuiTypography-body1 {
  width: 100% !important;
}

/*.sidenav {
  height: 97%; !* Full-height: remove this if you want "auto" height *!
  width: 19%; !* Set the width of the sidebar *!
  position: fixed; !* Fixed Sidebar (stay in place on scroll) *!
  z-index: 1; !* Stay on top *!
  top: 0; !* Stay at the top *!
  left: 0;
  overflow-x: hidden; !* Disable horizontal scroll *!
  padding-top: 20px;
  margin-top: 1%;
}

.main {
  margin-right: 10%; !* Same as the width of the sidebar *!
  padding: 0px 10px;
}*/
.edit__edit-cover-calender {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 32px;
  top: 0;
  left: -5px;
  margin: 1%;
  cursor: pointer;
  color: #a8192b;
  justify-content: center;
  align-items: center;
  /*z-index: 1000;*/
  display: none;
}
.edit__edit-cover-calender- {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  bottom: -15px;
  left: -21px;
  margin: 1%;
  cursor: pointer;
  color: #a8192b;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.selected:hover > .caldenderDayCont .edit__edit-cover-calender {
  display: flex;
}

.select {
  height: 4rem;
  color: var(--color-text-secondary);
  padding-right: 5rem;
  text-align: right;
  background: white;
  width: 100%;
  border-radius: 5rem;
  box-shadow: 0 0.3rem 2rem rgba(79, 79, 79, 0.19);
  font-size: var(--font-signup-sm);
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  height: 4rem;
  padding-bottom: 0.2rem;
}
.select::placeholder {
  color: rgb(90, 90, 90);
}

.question span {
  padding: 7px;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}

.question span:hover {
  background-color: #a8192b;
  color: white;
}

.emoji-picker-react {
  margin-top: -52% !important;
}
.ques .emoji-picker-react {
  margin-top: -40% !important;
}

.MuiSvgIcon-fontSizeMedium {
  font-size: 20px !important;
}

.picker > aside.emoji-picker-react {
  margin-right: -164% !important;
  margin-top: -259% !important;
}
.picker {
  top: -176% !important;
}
.option-task {
  display: none;
}
.task:hover .option-task {
  display: flex;
}

.task:hover .msng_card {
  background-color: #e1e1e1 !important;
}

.task:hover {
  background-color: #e1e1e1 !important;
  pointer-events: unset;
}
.msg-sent > svg {
  display: none;
}

.msg-sent:hover > svg {
  display: flex;
}

.msg-received > svg {
  display: none;
}

.msg-received:hover > svg {
  display: flex;
}
/*.slick-slide {
  width: 540px !important;
  text-align: center !important;
}*/
select {
  padding-right: 3%;
}

.demo-editor {
  height: 380px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.editor-class {
  padding: 2%;
  max-width: 100%;
}
.DraftEditor-root {
  max-width: 641px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.public-DraftStyleDefault-ltr {
  direction: rtl;
}

/*
*/
.fc-timegrid-slots > table > tbody > tr {
  height: 80px !important;
}

/*.fc-timegrid-event-harness-inset .fc-timegrid-event {
  height: 85px !important;
}*/

.fc-event-main {
  display: flex;
}
.dot {
  height: 30px;
  width: 30px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.fc-button-group > button {
  border: 1px solid #a8192b !important;
  margin: 2px !important;
  font-size: 12px !important;
  width: 60px !important;
  background-color: #a8192b !important;
  border-radius: 5px !important;
}
.fc-today-button {
  border: 1px solid #a8192b !important;
  font-size: 12px !important;
  width: 60px !important;
  background-color: #a8192b !important;
}
.fc-daygrid-day-bottom {
  font-size: 12px !important;
}
.fc-col-header-cell-cushion {
  font-size: 11px !important;
}
.fc-dayGridMonth-view .fc-daygrid-body > table > tbody > tr > td > div {
  height: 160px !important;
  border-radius: 5px !important;
}

.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-col-header {
  width: 100% !important;
}
.fc-day,
.fc-timegrid-slot-label,
.fc-col-header-cell,
.fc-timegrid-axis,
.fc-timegrid-slot-lane {
  background-color: white !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

* {
  margin: 0px;
}

#sidebar {
  /*Strictly Necessary */
  position: fixed;
  height: 100%;
  width: 12%;
  margin: 0px;
  margin-top: 2px;
  /*Aesthetics*/
  border-radius: 20px;
  margin-right: 6px;
}

#rightSideWrapper {
  /*Strictly Necessary */
  width: 88%;
  float: left;
}

#header {
  padding: 10px;
  margin-right: 14px;
  /*Strictly Necessary */
  position: fixed;
  /*width: 86.2%;*/
  height: 100px; /*Adjust the hight to your purposes*/

  /*Aesthetics*/
  border-radius: 5px;
}

.ContentBox {
  overflow: auto;
  padding: 15px;
  margin-top: 50px;
  display: flex;
  flex-flow: row wrap;
  height: 70rem;
}

.ContentBox > div {
  width: 100%;
  /*height: 600px;*/
}
.fc-scroller {
  overflow: hidden !important;
}

.sandwich_menu_btn {
  width: 2rem;
  cursor: pointer;
}

.fc-scroller-liquid-absolute {
  position: inherit !important;
}

.MuiTooltip-popper > div {
  font-size: 15px !important;
}
.fc-daygrid-day-number,
.fc-popover-title {
  font-size: 13px !important;
}
.fc-dayGridMonth-view .fc-scrollgrid-sync-table > tbody > tr > td {
  height: 200px !important;
}

.MuiFormControlLabel-root {
  width: 40rem !important;
}
.MuiTypography-root {
  font-size: 1.5rem !important;
}

.slick-slider > ul > li > button {
  min-height: 0;
  border-color: rgb(189, 35, 60) !important;
  background-color: rgb(189, 35, 60) !important;
}
.slick-list {
  height: 310px !important;
}

#contact .col-lg-6 {
  padding-right: 35px !important;
}

#root .btn-primary {
  background: rgb(189, 35, 60) !important;
  background-color: rgb(189, 35, 60) !important;
  border-color: rgb(189, 35, 60) !important;
}
#root .bg-primary {
  background: rgb(189, 35, 60) !important;
  background-color: rgb(189, 35, 60) !important;
  border-color: rgb(189, 35, 60) !important;
}
.slick-cloned {
  width: 1080px !important;
}

.media .pl-4 {
  padding-right: 3rem !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}
.media .btns-actions {
  display: none !important;
}
.media:hover .btns-actions {
  display: inherit !important;
}

.job-status-bar {
  float: left;
  width: 100%;
}
.like-com {
  float: right;
  margin-top: 0px;
}
.like-com li {
  display: inline-block;
  margin-right: 15px;
}
.like-com li a {
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 500;
  float: left;
  margin-top: 6px;
  margin-right: 5px;
}
.like-com li a:hover {
  color: #e44d3a;
}
.like-com li a.active {
  color: #e44d3a;
}
.like-com li a i {
  margin-right: 2px;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.like-com li span {
  color: #ffffff;
  font-size: 13px;
  width: 30px;
  height: 30px;
  background-color: #e44d3a;
  line-height: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  float: left;
  text-align: center;
  font-weight: 600;
  margin-left: -25px;
}
.like-com li .com {
  position: relative;
}
.like-com li .com img {
  margin-right: 10px;
}

.job-status-bar > a {
  float: left;
  color: #b2b2b2 !important;
  font-size: 14px;
  margin-top: 29px;
  cursor: pointer;
}
.job-status-bar > a:hover {
  color: #e44d3a !important;
}
.job-status-bar > a i {
  font-size: 14px;
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.ed-opts {
  float: left;
  position: relative;
  top: 7px;
}
.ed-opts > a {
  color: #b2b2b2;
  font-size: 24px;
  float: left;
  position: relative;
  left: 10px;
  top: -10px;
}
.ed-options {
  border-radius: 10px;
  position: absolute;
  top: 70%;
  left: 0;
  width: 130px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}
.ed-options.active {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.ed-options-emoji {
  position: absolute;
  top: 80%;
  left: 0;
  width: 130px;
  height: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.28);
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}
.ed-options-emoji.active2 {
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.ed-options.active li:hover {
  background: #e0e0e0;
  width: 100%;
}

.ed-options li {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.ed-options li:last-child {
  margin-bottom: 0;
}
.ed-options li a {
  color: #686868;
  font-size: 14px;
  font-weight: 600;
}
.ed-opts > a:hover {
  color: #e44d3a;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fc-daygrid-day-top {
  justify-content: flex-end;
}

.react-datepicker-wrapper {
  width: 10% !important;
}
.side {
  overflow: auto !important;
}

.ql-editor {
  text-align: right !important;
}

input[type='radio']:checked {
  border-color: #4caf50 !important;
  background-color: #4caf50 !important;
  border: 0;
}
