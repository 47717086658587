//
// screenshot.scss
//



.bg-services{
  background-image: url(../images/bg-2.png);
}


.services-box{
  box-shadow: $box-shadow;
  border-radius: 20px;
  .services-icon{
    width: 55px;
    height: 55px;
    line-height: 60px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    i{
     
      font-size: 28px;
      color: $primary;
      
    }
  }
}



.bg-features{
  border-radius: 0px 0px 350px 0px;
}