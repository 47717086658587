@font-face {
  font-family: 'beIN Normal';
  src: url('../fonts/beIN/beIN_Normal.ttf');
}

/* CUSTOM VARIABLES */
:root {
  --font-lg: 2rem;
  --font-md: 1.8rem;
  --font-sm: 1.7rem;
  --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.home_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  gap: 3rem;
  /* padding: 0 1rem; */
  margin-top: 1rem;
}

.side {
  font-size: var(--font-lg);
  flex: 0.6;
}
.side__mobile_menu {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  width: 100%;
  background: #fff;
  height: 5rem;
}
.side__mobile_menu_nav {
  position: absolute;
  top: 0;
  left: 0;
}

.side__mobile_menu_nav svg {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 1rem;
  transform: translateY(50%);
  fill: #ca2946;
  cursor: pointer;
  height: 25px;
}

.side__mobile_menu_rest {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 6rem;
  width: 100%;
}
.top_bar__pic,
.side__mobile_menu_rest svg {
  z-index: 102;
}

.mobile_page_title {
  font-size: var(--font-lg);
}

.side__mobile_menu_rest svg {
  height: 29px;
}

.home_main {
  flex: 3.5;
}

.side__menu {
  width: 100%;
}

/* END OF GLOBAL STYLE */

.active_a {
  background: var(--gradient-primary-toright);
  color: white;
}

.active_svg text,
.active_svg polygon,
.active_svg polyline,
.active_svg line,
.active_svg polygon,
.active_svg rect,
.active_svg circle,
.active_svg ellipse,
.active_svg svg,
.active_svg path {
  fill: white;
}

#Capa_1 {
  height: 3rem;
}
#Capa_1 text,
#Capa_1 polygon,
#Capa_1 polyline,
#Capa_1 line,
#Capa_1 polygon,
#Capa_1 rect,
#Capa_1 circle,
#Capa_1 ellipse,
#Capa_1 svg,
#Capa_1 path {
  fill: #ca2946;
}

.side ul,
.side li {
  width: 100%;
}

.side li a {
  width: 100%;
  background-color: white;
  margin-bottom: 1.2rem;
  height: 6rem;
  border-radius: 1.1rem 0 0 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.side li {
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.11);
  position: relative;
}

.side li::after {
  content: '';
  position: absolute;
  background: #ca2946;
  width: 2rem;
  height: 100%;
  border-radius: 0px 1.1rem 1.1rem 0px;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.11);
  z-index: -1;
  right: 0;
  top: 0;
  transform: translateY(0.1rem);
  transform: translateX(50%);
}

.msng_dot_div {
  display: flex;
}
.msng_dot_div svg {
  align-self: center;
}
.msng_dot_div__inner {
  background: green;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: none;
}

.top_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  min-height: 4rem;
}

.top_bar__el-right,
.top_bar__identifier,
.top_bar__el-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.top_bar__el-right {
  font-size: var(--font-lg);
}

.top_bar__el-left {
  font-size: var(--font-xs);
}

.home_main__title {
  color: var(--color-primary-red);
}

.home_main__date {
  font-size: var(--font-sm);
}

.side__logo {
  margin-top: 3rem;
  margin-right: 25%;
}
.side__logo img {
  height: 12rem;
}

.top_bar__identifier {
  gap: 1rem;
}

.top_bar_mobile-back {
  height: 30px;
  z-index: 101;
}

.top_bar__pic {
  width: 2.8rem;
  height: 2.8rem;
  background: var(--color-secondary-red);
  border-radius: 50%;
  color: white;
  position: relative;
}

.top_bar__user_initial {
  position: absolute;
  line-height: 0;
  top: 1.4rem;
  left: 1.4rem;
  transform: translate(-50%);
  font-size: 2rem;
}

.user_dropdown {
  cursor: pointer;
  padding-right: 3rem;
  position: relative;
  direction: ltr;
  text-align: center;
}

.user_dropdown__content {
  display: block;
  position: absolute;
  background: #f5f5f5 !important;
  min-width: 16rem;
  overflow: auto;
  box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  flex-direction: column;
  border-radius: 2px;
}

.user_dropdown__content li:hover {
  background: #ddd;
}

.user_dropdown__content li {
  text-align: initial;
  padding: 0.5rem 1.6rem;
  min-height: 3.8rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_dropdown__content li > span {
  font-size: 16px;
}
.user_dropdown__content li > a {
  font-size: 16px;
  width: 100%;
}
.user_dropdown__content a > svg {
  width: 2rem;
  margin-left: 8%;
}

.top_bar_notifications {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.noti_dropdown__content_mobile {
  display: none;
  position: absolute;
  background: #fff;
  width: 100%;
  box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  flex-direction: column;
  top: 4.5rem;
  justify-content: start;
}
.noti_dropdown__content {
  display: none;
  position: absolute;
  background: #fff;
  /* overflow: auto; */
  box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  flex-direction: column;
  bottom: 0;
  right: -1.3rem;
  transform: translateY(calc(100% + 0.8rem));
  min-width: 23rem;
  justify-content: start;
}

.noti_dropdown__content_mobile a,
.noti_dropdown__content a {
  padding: 0 1.6rem;
  /* min-height: 3rem; */
  padding-top: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.5rem;
  border-top: 1px solid #eeeeee;
}
.noti_dropdown__content_mobile svg,
.noti_dropdown__content svg {
  height: 3.5rem;
  transform: translateY(-0.5rem);
}
.noti_dropdown {
  transform: translateY(-0.4rem);
}
.noti_text {
  font-size: 1.4rem;
  /* transform: translateY(-.5rem); */
}
.noti_text .span_date {
  font-weight: bold;
}

.noti_mobile_date .span_time {
  color: #606060;
  margin-right: 1rem;
}

.noti_category {
  line-height: 0.9;
  font-size: 1.5rem;
}
.single_noti_content {
  display: flex;
  flex-direction: column;
}
.noti_more {
  color: var(--color-primary-red);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #eeeeee;
  gap: 1rem;
  font-size: 1.4rem;
}
.noti_more a {
  padding: 0.5rem 0;
}
.noti_more svg {
  display: block;
  align-self: center;
  transform: translateY(0.1rem);
}
/* table style */

.home_table_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem;
  border-radius: 0.8rem;
  font-size: var(--font-md);
  min-height: 4rem;
}

.home_table_title svg,
.home_table_title img {
  display: block;
  align-self: center;
}

.home_table_title-red {
  color: white;
  transition: 0.1s;
  background: var(--gradient-primary-toright);
}

.home_table_title-white {
  background: white;
  color: var(--color-primary-red);
  margin-bottom: 0.1rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.home_table_title-red:hover {
  opacity: 0.9;
}

.home_table table {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
}

.home_table table tbody tr {
  height: 4.4rem;
  border: 1px solid #eeeeee;
}
.home_table table tr {
  border: 1px solid #eeeeee;
}

.home_table table td,
.home_table table th {
  padding-right: 1.5rem;
}

.home_table table td:nth-child(3),
.home_table table th:nth-child(3) {
  text-align: center;
}

.home_table thead tr {
  height: 4.2rem;
}
.home_table thead {
  color: var(--color-primary-red);
  font-size: var(--font-xs);
}

.home_table tfoot tr {
  height: 4rem;
}
.home_table tfoot td,
.home_table tbody td {
  font-size: var(--font-sm);
}

.home_table table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.home_table table tbody tr:nth-child(even) {
  background: #fff;
}

.home_table table tfoot {
  text-align: center;
  background: #f9f9f9;
}

.home_table__footer {
  color: var(--color-primary-red);
  cursor: pointer;
  font-size: var(--font-sm);
}

.home_table__footer svg {
  margin-right: 2rem;
}

/* FIX THSI */
.home__jalaset_table table tbody td:nth-child(3) {
  padding-right: 2rem;
  position: relative;
}

.home__judgements_table table tbody td:nth-child(4) {
  padding-right: 2rem;
}

.table_location_pin {
  position: absolute;
  transform: translate(2rem, 1rem);
}

/* end table style */
.home_middle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
  height: 100%;
  /*
  overflow: auto;
  */
}

.home_middle__col-right {
  display: flex;
  flex-direction: column;
  /*gap: 2rem;*/
  flex: 3;
}

.home_middle__col-left {
  flex: 1;
}

.home_reminder_date {
  background: #f5f5f5;
  margin-top: 0.5rem;
  padding: 0.8rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-xs);
}

.gt {
  color: var(--color-primary-red);
  font-size: var(--font-lg);
  margin-right: 2rem;
}

.home_travels {
  margin-top: 3vh;
}

.home_travels__city {
  background: white;
  display: flex;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: var(--font-sm);
  padding: 0.8rem 0;
}

.home_travels__city svg {
  margin: 0 1rem;
}

.home_fixed {
  width: 100%;
  height: 4rem;
  background-color: #fff;
  position: fixed;
  z-index: 100;
  bottom: 0;
  display: none;
}
.home_fixed_btn svg {
  width: 3rem;
}
.home_fixed_btn {
  position: fixed;
  z-index: 101;
  bottom: 1rem;
  background: linear-gradient(180deg, #440509, #b41f35);
  width: 5rem;
  height: 5rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
}

/* calendar style */
.calendar__month_bar,
.calendar__weekdays,
.calendar__days {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: var(--font-xs);
}

.calendar__month_bar {
  color: white;
  background: var(--color-primary-red);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 3.2rem;
  align-items: center;
}

.calendar__month_bar svg {
  cursor: pointer;
}

.calendar__weekdays {
  font-size: 1.2rem;
  text-align: center;
  margin: 1.2rem 0;
}

.calendar__weekdays,
.calendar__days {
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar__days {
  background: white;
  height: calc(6 * 4.5ch);
}

/* Style for each day */
.calendar__days button {
  outline: none;
  border: none;
  position: relative;
  border: 0;
  width: 4.5ch;
  height: 4.5ch;
  background-color: white;
  color: var(--blue-grey-600);
  z-index: 100;
}

.calendar__days button:hover,
.calendar__days button:focus {
  outline: none;
  background-color: rgba(255, 0, 0, 0.466);
  color: white;
}

.calendar__days button:active,
.calendar__days button.is-selected {
  color: white;
  background: var(--color-primary-red);
}

.calendar_marked_day {
}

/* Positioning the first day on a Friday */
/* .calendar__days button:first-child {
    grid-column: 4;
  } */

/* end of calendar style */
/******************************/
/* utils */
.home_fill_path {
  fill: white;
}

.show_block {
  display: block;
}

.show_flex {
  display: flex;
}

.rotate_svg {
  transform: rotate(180deg);
}

.hide {
  display: none;
}
/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
  /* CUSTOM VARIABLES */
  /* smaller font sizes */
  /* :root {
        --font-lg: 1.8rem;
        --font-md: 1.7rem;
        --font-sm: 1.6rem;
        --font-xs: 1.5rem;
    } */

  /* END OF CUSTOM VARIABLES */
  /*.home_container {
    max-width: 1300px;
    margin-top: 4rem;
  }*/

  /* size of svg icons in side menu */
  .side_menu_svg {
    width: 2.7rem;
    align-self: center;
  }

  /* height of list items in side menu */
  .side li a {
    height: 4.2rem;
    margin-bottom: 0.8rem;
    border-radius: 1.1rem;
  }

  .home_side__logo {
    margin-right: 3vw;
  }

  /* responsive table */
  /* table {
        display: block;
        overflow-y: hidden;
        overflow-x:scroll;
        min-width: 55rem;
    }
    tbody, thead, tfoot {
        display: table;
        width: 100%;
    } */
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
  .home_container {
    /*width: 1000px;*/
    width: 100%;
  }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
  .home_container {
    /*width: 820px;
    margin-top: 9rem;*/
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
  .side {
    position: absolute;
    left: 1rem;
    display: none;
    min-width: 25rem;
    z-index: 100;
    /* padding: 0 .5rem; */
  }
  .side__mobile_menu {
    display: flex;
    align-items: center;
  }
  #side__mobile_menu-open {
    /* display: block; */
  }
  /* copy style to the other pages */
  .home_middle__col-left {
    display: none;
  }
  .side {
    top: 0;
    padding-top: 5rem;
  }
  .side li::after {
    display: none;
  }
  .top_bar {
    display: none;
  }
  .top_bar__pic {
    cursor: pointer;
  }
  .side__mobile_menu_rest svg {
    cursor: pointer;
  }
  .main__date {
    color: #77121f;
    width: 100%;
    position: absolute;
    top: 100%;
    padding-right: 2rem;
    font-size: var(--font-lg);
  }
  .home_fixed {
    display: block;
  }
  table thead th {
    font-size: 1.4rem;
  }
  .table_location_pin {
    display: none;
  }
  .side__logo {
    display: none;
  }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
  .home_container {
    /*width: 600px;
    padding-bottom: 6rem;*/
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
  }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
  .home_container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
  .side {
    background: #fff;
    width: 100%;
    padding: 5rem 0 0 0;
    right: 0;
    height: 100%;
  }
  .side li a {
    height: 4.2rem;
  }
}
