/* CUSTOM VARIABLES */
:root {
  --font-lg: 2rem;
  --font-md: 1.8rem;
  --font-sm: 1.7rem;
  --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.jalaset_container {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  gap: 3rem;
  /* padding: 0 1rem; */
  margin-top: 4rem;
}

.jalaset_main {
  flex: 3.5;
}

/* END OF GLOBAL STYLE */

.jalaset_main__title {
  color: var(--color-primary-red);
}

.jalaset_main__date {
  font-size: var(--font-sm);
}

.jalaset_side__logo {
  margin-top: 3rem;
  margin-right: 8vw;
}

.jalaset_top_bar__identifier {
  gap: 0.5rem;
}

.jalaset_top_bar__pic {
  width: 2.8rem;
  height: 2.8rem;
  background: var(--color-secondary-red);
  border-radius: 50%;
  color: white;
  position: relative;
}

.jalaset_top_bar__user_initial {
  position: absolute;
  line-height: 0;
  top: 1.4rem;
  left: 1.4rem;
  transform: translate(-50%);
  font-size: 2rem;
}

/* table style */

.jalaset_table table {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
}

.jalaset_table table thead tr {
  height: 4.2rem;
}
.jalaset_table table tfoot tr {
  height: 3.8rem;
}
.jalaset_table table tbody tr {
  height: 4.2rem;
  border: 1px solid #eeeeee;
}

.jalaset_table table tr {
  border: 1px solid #eeeeee;
}

.jalaset_table table td,
.jalaset_table table th {
  padding-right: 1.5rem;
}

.jalaset_table table td:nth-child(2),
.jalaset_table table th:nth-child(2) {
  /* text-align: right; */
}

.jalaset_table thead {
  color: #606060;
  font-size: var(--font-xs);
  background: #e5e5e5;
}

.jalaset_table tbody td {
  font-size: var(--font-sm);
}

.jalaset_table table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.jalaset_table table tbody tr:nth-child(even) {
  background: #fff;
}

.jalaset_table table tfoot {
  text-align: center;
  background: #f9f9f9;
}

.jalaset__judgements_table table tbody td:last-child {
  display: flex;
  align-items: center;
  /* padding-right: 2rem; */
  gap: 2rem;
}

.jalaset__judgements_table table tbody td:last-child svg {
  height: 20px;
}

.jalaset__judgements_table table tbody td:last-child div {
  cursor: pointer;
  /* transform: translateY(25%); */
}

.jalaset__judgements_table table thead th:nth-child(3),
.jalaset__judgements_table table tbody td:nth-child(3) {
  padding-right: 8rem;
}

/* end table style */

.jalaset_middle {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
}

/* calendar style */
.jalaset_calendar {
  display: none;
}

.calendar__month_bar,
.calendar__weekdays,
.calendar__days {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: var(--font-xs);
}

.calendar__month_bar {
  color: white;
  background: var(--color-primary-red);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 3.2rem;
  align-items: center;
}

.calendar__month_bar svg {
  cursor: pointer;
}

.calendar__weekdays {
  font-size: 1.2rem;
  text-align: center;
  margin: 1.2rem 0;
}

.calendar__weekdays,
.calendar__days {
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar__days {
  background: white;
  height: calc(6 * 4.5ch);
}

/* Style for each day */
.calendar__days button {
  outline: none;
  border: none;
  position: relative;
  border: 0;
  width: 4.5ch;
  height: 4.5ch;
  background-color: white;
  color: var(--blue-grey-600);
  z-index: 100;
}

.calendar__days button:hover,
.calendar__days button:focus {
  outline: none;
  background-color: rgba(255, 0, 0, 0.466);
  color: white;
}

.calendar__days button:active,
.calendar__days button.is-selected {
  color: white;
  background: var(--color-primary-red);
}

.calendar_marked_day {
}

/* Positioning the first day on a Friday */
/* .calendar__days button:first-child {
    grid-column: 4;
  } */

/* end of calendar style */

/* table ctonrols style */
.table_controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sort_grp {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sort_grp__txt {
  margin-left: 1.5rem;
  font-size: var(--font-sm);
}

.sort_grp__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f9f9f9;
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
}

.sort_grp__buttons svg {
  margin: 0 0.8rem;
}

.sort_grp__buttons svg:hover {
  cursor: pointer;
}

.jalaset_add_button svg {
  fill: #ffffff;
  margin-left: 2rem;
}

.jalaset_add_button {
  font-size: 1.6rem;
  color: #ffffff;
  background: #ca2946;
  border: none;
  padding: 0.1rem 1.2rem;
  border-radius: 0.8rem;
  /* width: 4rem; */
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'beIN Normal';
  min-height: 4rem;
}

.jalaset_add_button:hover {
  opacity: 0.8;
}

/* end of table controls */

/* new session style */
.jalaset_newSession {
  background: white;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 6rem;
  padding-bottom: 2rem;
  border-radius: 10px;
  display: none;
}

.jalaset_form__sections {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  gap: 7rem;
  font-size: var(--fonts-md);
}

.jalaset_form__btn_grp {
  display: flex;
  width: 100%;
  justify-content: end;
  padding-left: 2rem;
  margin-top: 5rem;
}

.jalaset_form__btn_grp button {
  border: none;
  padding: 0 1.8rem;
  font-size: var(--font-xs);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'beIN Normal';
}

.jalaset_form__btn_grp button {
  display: block;
  /* float: left; */
}

.jalaset_form__btn_grp .tickSpan {
  transform: translateY(20%);
  height: 15px;
  margin-left: 0.5rem;
}
.jalaset_ltSpan {
  /* font-size: var(--font-lg); */
  margin-left: 1.5rem;
}

.jalaset_form__btn_grp button:last-child {
  color: white;
  background: #63b17d;
}

.jalaset_form__sections-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.3;
  gap: 1rem;
  font-size: var(--font-xs);
}

.jalaset_form__sections-right div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.jalaset_form__sections-right input,
.jalaset_form__sections-right textarea,
.jalaset_form__sections-right select {
  width: 70%;
  height: 2.8rem;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
}
.rv_form__sections-left input,
.rv_form__sections-left textarea,
.rv_form__sections-left select {
  width: 70%;
  height: 2.8rem;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
}

.jalaset_form__sections-right input,
.jalaset_form__sections-right textarea {
  padding: 1rem;
}
.jalaset_form__sections-right textarea {
  height: 12rem;
}
.jalaset_form__sections-right .MuiAutocomplete-root {
  /*margin-right: 16%;*/
  width: 100%;
}

.jalaset_form__sections-left {
  flex: 1;
  font-size: var(--font-xs);
}

.jalaset_form__sections-left {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1rem;
}

.jalaset_form__label {
  margin-left: 4rem;
}

.jalaset_form__sections-left svg {
  transform: translateY(25%);
}

.jalaset_form__sections label,
.jalaset_form__sections-left div:first-child {
  color: #891423;
}

.jalaset_form__date_selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.1rem 1rem;
  /* transform: scale(0); */
}

.jalaset_calendar_icon {
  height: 25px;
  cursor: pointer;
}

/* end of new session style */

/* popup style */
.popup {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transition: 200ms ease-in-out;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 1.4rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  padding: 1rem;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  gap: 2rem;
  z-index: 1001;
}

.popup__title {
  align-self: center;
  font-size: var(--font-md);
}

.popup__item {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  font-size: var(--font-xs);
  padding: 0 2rem;
}

.popup__btn_grp button {
  border: none;
  padding: 0 0.8rem;
  font-size: var(--font-xs);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'beIN Normal';
  min-height: 4rem;
}

.popup__btn_grp button span {
  display: block;
  float: left;
  margin-left: 1rem;
}

.popup__btn_grp .trashSpan {
  transform: translateY(10%);
}

.popup__btn_grp .trashSpan svg {
  height: 20px;
}

.popup__btn_grp button:last-child {
  color: white;
  background: #ca2946;
}

.popup__court {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popup__court svg {
  width: 1rem;
}

.popup.active {
  transform: translate(-50%, -50%) scale(1);
}

/* end of popup style */

/* overlay style */
#overlay {
  transition: 200ms ease-in-out;
  opacity: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.16);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1000;
}

#overlay.active {
  opacity: 1;
  pointer-events: all;
}
/* end of overlay style */

/* calendar style */
.jalaset_form__cal {
  position: relative;
}
#jalaset_calendar {
  position: absolute;
  border: 1px solid #eee;
  padding: 0.5rem;
  z-index: 100;
  right: 0;
  transform: translateY(-0.5rem);
}

.jalaset_calendar {
  /* display: none; */
}

#jalaset_calendar__month_bar-white {
  background: white;
}
#jalaset_calendar__weekdays {
  color: #000;
  font-weight: bold;
}

/* end of calendar style */

/******************************/
/* utils */
.jalaset_fill_path {
  fill: white;
}

.show_block {
  display: block;
}

.show_flex {
  display: flex;
}

.rotate_svg {
  transform: rotate(180deg);
}

.hide_scale {
  transform: scale(0);
}

/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
  /* CUSTOM VARIABLES */
  /* smaller font sizes */
  :root {
    --font-lg: 1.8rem;
    --font-md: 1.7rem;
    --font-sm: 1.6rem;
    --font-xs: 1.5rem;
  }

  /* END OF CUSTOM VARIABLES */
  .jalaset_container {
    width: 1140px;
    margin-top: 4rem;
  }

  /* size of svg icons in side menu */
  .jalaset_menu_svg {
    width: 2.7rem;
  }

  /* height of list items in side menu */
  /* .side li a {
        height: 3.8rem;
        margin-bottom: .8rem;
    } */

  .jalaset_side__logo {
    margin-right: 3vw;
  }

  /* responsive table */
  /* table {
        display: block;
        overflow-y: hidden;
        overflow-x:scroll;
        min-width: 89rem;
    }
    tbody, thead, tfoot {
        display: table;
        width: 100%;
    } */
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
  .jalaset_container {
    width: 1000px;
  }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
  .jalaset_container {
    width: 820px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
    margin-top: 9rem;
  }
  .jalaset_middle__col-left {
    display: none;
  }
  .table_controls {
    display: none !important;
  }
  .jalaset_table table tbody tr {
    height: 4.4rem;
  }
  .jalaset_table th:nth-child(4),
  .jalaset_table td:nth-child(4) {
    display: none !important;
  }

  /* newSession style */
  .jalaset_form__sections {
    flex-direction: column;
  }
  .jalaset_form__sections-left,
  .jalaset_form__sections-right {
    width: 100%;
  }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
  .jalaset_container {
    width: 600px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
  .jalaset_container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
}
