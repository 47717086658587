/* CUSTOM VARIABLES */
:root {
  --font-signup-lg: 2rem;
  --font-signup-md: 1.8rem;
  --font-signup-sm: 1.7rem;
  --font-signup-xs: 1.6rem;
}
/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.signup_container {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  gap: 3rem;
  /* padding: 0 1rem; */
}
.signup_wrapper {
  width: 30vw;
  /* margin: 0 auto; */
  padding: 0 2rem;
}
/* END OF GLOBAL STYLE */

.signup_logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 3vh;
  margin-top: 7rem;
  /* height: 40rem; */
}
.signup_signup_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 3.5vh;
}

.signup_form_field {
  width: 100%;
  position: relative;
}
.signup_form_field input {
  height: 4rem;
  padding-bottom: 2rem;
  color: var(--color-text-secondary);
  padding-right: 5rem;
  text-align: right;
  background: white;
  width: 100%;
  border-radius: 5rem;
  box-shadow: 0 0.3rem 2rem rgba(79, 79, 79, 0.19);
  font-size: var(--font-signup-sm);
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
.signup_form_field select {
  height: 4rem;
  padding-bottom: 2rem;
  color: var(--color-text-secondary);
  padding-right: 5rem;
  text-align: right;
  background: white;
  width: 100%;
  border-radius: 5rem;
  box-shadow: 0 0.3rem 2rem rgba(79, 79, 79, 0.19);
  font-size: var(--font-signup-sm);
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
.signup_form_field input::placeholder {
  color: rgb(90, 90, 90);
}
.signup_form_field select::placeholder {
  color: rgb(90, 90, 90);
}
.signup_signup_form__icon {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.signup_submit_btn button {
  color: white;
  background: var(--gradient-primary-toleft);
  width: 100%;
  border-radius: 5rem;
  cursor: pointer;
  font-size: var(--font-signup-lg);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: none;
}

.signup_form_field select,
.signup_form_field input,
.signup_form_field button {
  height: 4rem;
  padding-bottom: 0.2rem;
}

.signup_alt-link {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #8f0e18;
  text-align: center;
  cursor: pointer;
  font-size: var(--font-signup-sm);
}

.signup_form_field__warning {
  width: 15px;
}

.signup_signup_form_field__error {
  font-family: 'beIN Normal';
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  display: none;
  flex-direction: row-reverse;
  position: absolute;
  top: 100%;
  transform: translateY(10%);
  height: 1.2rem;
  left: 2rem;
  z-index: 11;
  align-items: center;
  gap: 0.5rem;
  color: white;
  pointer-events: none;
  background: orange;
  padding: 1.2rem 1.1rem;
  border-radius: 0.5rem;
}
.signup_signup_form_field__error::before {
  position: absolute;
  content: '';
  top: -0.5rem;
  transform: rotate(45deg);
  height: 0.1rem;
  width: 0.1rem;
  left: 1.2rem;
  z-index: -1;
  align-items: center;
  color: white;
  pointer-events: none;
  background: orange;
  padding: 1.1rem;
  border-radius: 0.2rem;
}

/* UTILS */
.show_block {
  display: block;
}
.show_flex {
  display: flex;
}
/* END OF UTILS */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
  /* CUSTOM VARIABLES */
  /* smaller font sizes */
  :root {
    --font-signup-lg: 1.9rem;
    --font-signup-md: 1.7rem;
    --font-signup-sm: 1.5rem;
    --font-signup-xs: 1.4rem;
  }
  /* END OF CUSTOM VARIABLES */
  .signup_container {
    width: 1140px;
  }
  .signup_logo {
    height: 150px;
  }
  .signup_wrapper {
    width: 28vw;
    padding: 0 2rem;
  }
  .signup_signup_form__icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
  .signup_container {
    width: 1000px;
  }
  .signup_logo {
    height: 140px;
  }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
  .signup_container {
    width: 884px;
    padding: 0 2rem;
  }
  .signup_wrapper {
    width: 50vw;
    padding: 0 2rem;
  }
  .signup_signup_form__icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
  .signup_container {
    width: 600px;
  }
  .signup_wrapper {
    width: 100vw;
    padding: 0 1rem;
  }
  .signup_signup_form__icon {
    width: 1.4rem;
    height: 1.4rem;
  }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
  .signup_container {
    width: 480px;
    padding: 0 0.5rem;
  }
}
/******************************/
/* EVEN SMALLER */
@media only screen and (max-width: 470px) {
  .signup_container {
    width: 380px;
    padding: 0 0.5rem;
  }
}
