/* CUSTOM VARIABLES */
:root {
  --font-lg: 2rem;
  --font-md: 1.8rem;
  --font-sm: 1.7rem;
  --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.msng_container {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  gap: 3rem;
  /* padding: 0 1rem; */
  margin-top: 4rem;
}

.msng_main {
  flex: 3.5;
}

/* END OF GLOBAL STYLE */

.msng_top_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}

.msng_top_bar__el-right,
.msng_top_bar__identifier,
.msng_top_bar__el-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.msng_top_bar__el-right {
  font-size: var(--font-lg);
}

.msng_top_bar__el-left {
  font-size: var(--font-xs);
}

.msng_main__title {
  color: var(--color-primary-red);
}

.msng_main__date {
  font-size: var(--font-sm);
}

.msng_side__logo {
  margin-top: 3rem;
  margin-right: 8vw;
}

.msng_top_bar__identifier {
  gap: 0.5rem;
}

.msng_top_bar__pic {
  width: 2.8rem;
  height: 2.8rem;
  background: var(--color-secondary-red);
  border-radius: 50%;
  color: white;
  position: relative;
}

.msng_top_bar__user_initial {
  position: absolute;
  line-height: 0;
  top: 1.4rem;
  left: 1.4rem;
  transform: translate(-50%);
  font-size: 2rem;
}

/* table style */

/* end table style */

.msng_middle {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
}

/* calendar style */

/* end of calendar style */

/* table ctonrols style */
/* .table_controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
} */

.msng_add_button svg {
  fill: #ffffff;
  margin-left: 2rem;
}

.msng_add_button {
  font-size: 1.6rem;
  color: #ffffff;
  background: #ca2946;
  border: none;
  padding: 0.1rem 1.5rem;
  border-radius: 0.8rem;
  /* width: 4rem; */
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'beIN Normal';
  min-height: 4rem !important;
}

.msng_add_button:hover {
  opacity: 0.8;
}

/* end of table controls */

/* new session style */
.msng_newClient {
  background: white;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 6rem;
  padding-bottom: 2rem;
  display: none;
}

.msng_form__sections {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 7rem;
  font-size: var(--fonts-md);
}

.msng_form__btn_grp {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-left: 2rem;
  margin-top: 5rem;
}

.msng_form__btn_grp button {
  border: none;
  padding: 0 1.8rem;
  font-size: var(--font-xs);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'beIN Normal';
}

.msng_form__btn_grp button {
  display: block;
  /* float: left; */
  margin-left: 1rem;
}

.msng_form__btn_grp .tickSpan {
  transform: translateY(20%);
  height: 15px;
  margin-left: 0.5rem;
}

.msng_ltSpan {
  /* font-size: var(--font-lg); */
  margin-left: 1.5rem;
}

.msng_form__btn_grp button:last-child {
  color: white;
  background: #63b17d;
}

.msng_form__sections-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.3;
  gap: 1rem;
  font-size: var(--font-xs);
}

.msng_form__sections-right div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.msng_form__sections-right input,
.msng_form__sections-right select {
  width: 70%;
  height: 2.8rem;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
}

.msng_form__sections-right input {
  padding: 1rem;
}

.msng_form__sections-left {
  flex: 1;
  font-size: var(--font-xs);
}

.msng_form__sections-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.msng_form__label {
  margin-left: 4rem;
}

.msng_form__sections-left svg {
  transform: translateY(25%);
}

.msng_form__sections label,
.msng_form__sections-left div:first-child {
  color: #891423;
}

.msng_form__date_selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.1rem 1rem;
  /* transform: scale(0); */
}

.msng_calendar_icon {
  height: 25px;
  cursor: pointer;
}

/* end of new session style */

/* popup style */

/* end of popup style */

/* overlay style */

/* end of overlay style */

/* calendar page-specific style */

/* end of calendar style */

/* clients content style */
.msng_middle__wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.msng_col-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.msng_right_search input {
  width: 100%;
  /* height: 3rem; */
  padding: 0.6rem 3.2rem;
  font-size: 1.7rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 0.7rem;
}

.msng_right_search {
  width: 100%;
  position: relative;
  /* height: 3.5rem; */
  /* margin-top: 0.5rem; */
}

/* FIX THIS */
.msng_right_search img {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-55%);
  z-index: 10;
}

.msng_cards {
  display: flex;
  align-items: center;
}

.msng_card {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  background: #f6f6f6;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  font-size: 1.5rem;
}

.msng_card__name {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.msng_card__pic {
  display: block;
  min-width: 3.5rem;
  height: 3.5rem;
  background: #ca2946;
  border-radius: 50%;
  color: white;
  position: relative;
}

.msng_card__pic div {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 2rem;
  font-weight: bold;
}

/* hide the left arrow for non-selected cards */
.msng_card svg {
  display: none;
}

/* sho left arr and change boackground for selected card */
.selectedCard {
  background-color: #ffffff;
}

.msng_card svg {
  display: none;
}
.msng_card:hover svg {
  display: block;
}

.msng_col-left {
  flex: 3.3;
}

.msng_details_section {
  background: white;
  font-size: 1.6rem;
  margin-left: 0;
  padding-bottom: 1rem;
  width: 100%;
}

.msng_details_section__head {
  /* border-bottom: 1px solid #EEEEEE; */
  /* height: 3rem; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.6rem;
  padding-right: 1rem;
  padding-bottom: 0.4rem;
  width: 100%;
}

.msng_details_section__head::after {
  content: '';
  position: absolute;
  width: calc(100% + 4rem);
  height: 0.1rem;
  background: #eeeeee;
  right: 0;
  bottom: 0.1rem;
  transform: translateX(4rem);
}

.msng_details_section__head svg {
  height: 20px;
  cursor: pointer;
  display: none;
}

.msng_details_section__body {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  padding-top: 2.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  overflow-y: scroll;
  height: calc(100vh - 21.5rem);
}

.msg-received,
.msg-sent {
  width: 100%;
  display: flex;
}

.msg-received .msg-content,
.msg-sent .msg-content {
  padding: 0 1.2rem;
  border-radius: 2rem;
  width: max-content;
  font-size: 1.6rem;
  margin-top: 0.5rem;
  display: flex;
  max-width: 60%;
  flex-wrap: wrap;
}

.msg-sent .msg-content {
  color: #fff;
  background: #ca2946;
}

.msg-received .msg-content {
  background: #eee;
}
.msg-received {
  flex-direction: row-reverse;
}

.msg-field input,
.msg-field {
  border-radius: 0.6rem;
}
.msg-field {
  width: 100%;
  margin-top: 0.1rem;
  position: relative;
  /* background: #fff; */
}
.msg-field input {
  padding: 1.2rem 1rem;
  font-size: 1.5rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: calc(100% - 9.2rem);
}
.msg-field__btn {
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
  min-width: 9rem;
  max-height: 1.5rem;
  padding: 0 2rem;
  background: #ca2946;
  color: #fff;
  font-size: var(--font-lg);
  outline: none;
  border: none;
  border-radius: 0.5rem;
}

.msng_card__name {
  display: flex;
  position: relative;
}
.msng_card__name-txt {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.msg_snippet {
  font-size: 1.3rem;
  color: #9e9e9e;
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(75% - 0.8rem));
}
/* end of clients content style */

/******************************/
/* utils */
.msng_fill_path {
  fill: white;
}

.show_block {
  display: block;
}

.show_flex {
  display: flex;
}

.rotate_svg {
  transform: rotate(180deg);
}

.hide_scale {
  transform: scale(0);
}

.hide {
  display: none;
}
/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
  /* CUSTOM VARIABLES */
  /* smaller font sizes */
  :root {
    --font-lg: 1.8rem;
    --font-md: 1.7rem;
    --font-sm: 1.6rem;
    --font-xs: 1.5rem;
  }

  /* END OF CUSTOM VARIABLES */
  .msng_container {
    width: 1140px;
    margin-top: 4rem;
  }

  /* size of svg icons in side menu */
  .msng_menu_svg {
    width: 2.7rem;
  }

  /* height of list items in side menu */
  .msng_container li a {
    height: 3.8rem;
    margin-bottom: 0.8rem;
  }

  .msng_side__logo {
    margin-right: 3vw;
  }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
  .msng_container {
    width: 1000px;
  }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
  .msng_container {
    width: 820px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
    margin-top: 6rem;
  }
  .msng_col-right {
    /* display: none; */
  }
  .msng_col-left {
    display: none;
    width: 100%;
    overflow: hidden;
    height: 90%;
    /* position: absolute; */
  }

  .msng_form__sections {
    flex-direction: column;
  }
  .msng_form__sections-right {
    width: 100%;
  }
  .msng_card {
    background: #fff;
  }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
  .msng_container {
    width: 600px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
  .msng_container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
}

.msg-content {
  word-wrap: break-word;
  word-break: break-word;
}
.msg-sent .msg-content span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  text-align: right;

  color: #ffffff;
}

.msg-received .msg-content {
  background: #f3f4f9;
  border-radius: 17px 17px 17px 0px;
  padding: 5px 20px;
}

.msg-received .msg-content span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  color: #1a1c1d;
}
.msg-sent {
  position: relative;
  display: flex;
}
.msg-sent .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 0;
  /* Position the tooltip */
  z-index: 1;
  /* top: 50%;
  left: 25%; */
  height: 30px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.msg-sent:hover .tooltiptext {
  visibility: visible;
}

.msg-received .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 0;

  /* Position the tooltip */
  z-index: 1;
  top: 70%;
  right: 25%;
  height: 30px;
}
.msg-received {
  position: relative;
  display: flex;
}
/* Show the tooltip text when you mouse over the tooltip container */
.msg-received:hover .tooltiptext {
  visibility: visible;
}
