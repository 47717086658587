/* CUSTOM VARIABLES */
:root {
    --font-login-lg: 2rem; 
    --font-login-md: 1.8rem; 
    --font-login-sm: 1.7rem; 
    --font-login-xs: 1.6rem; 
}
/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.login_container {
    width: 1200px;
    display: flex;
    justify-content: center;
    margin: 0rem auto;
    gap: 3rem;
    /* padding: 0 1rem; */
}
.login_wrapper {
    width: 30vw;
    /* margin: 0 auto; */
    padding: 0 2rem;
}
/* END OF GLOBAL STYLE */

.login_logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 3vh;
    margin-top: 7rem;
    /* height: 40rem; */
}
.login_login_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 3vh;
}

.login_form_field {
    width: 100%;
    position: relative;
}
.login_form_field input {
    height: 4rem;
    padding-bottom: 2rem;
    color: var(--color-text-secondary);
    padding-right: 5rem;
    text-align: right;
    background: white;
    width: 100%;
    border-radius: 5rem;
    box-shadow: 0 .3rem 2rem rgba(79, 79, 79, 0.19);
    font-size: var(--font-login-sm);
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.login_form_field input::placeholder {
    color: rgb(90, 90, 90);
}
.login_login_form__icon {
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}

.login_submit_btn button{
    color: white;
    background: var(--gradient-primary-toleft);
    width: 100%;
    border-radius: 5rem;
    cursor: pointer;
    font-size: var(--font-login-lg);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: none;
}

.login_form_field input,
.login_form_field button {
    height: 4rem;
    padding-bottom: .2rem;
}

.login_alt-link {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #8F0E18;
    text-align: center;
    cursor: pointer;
    font-size: var(--font-login-sm);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}


/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
    /* CUSTOM VARIABLES */
        /* smaller font sizes */
    :root {
        --font-signup-lg: 1.9rem; 
        --font-signup-md: 1.7rem; 
        --font-signup-sm: 1.5rem; 
        --font-signup-xs: 1.4rem; 
    }
    /* END OF CUSTOM VARIABLES */
    .login_container {
        width: 1140px; 
    }
    .login_logo {
        height: 180px;
    }
    .login_wrapper {
        width: 28vw;
        padding: 0 2rem;
    }
    .login_login_form__icon {
        width: 1.6rem;
        height: 1.6rem;
    }

}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
    .login_container {
        width: 1000px;
    }
    .login_logo {
        /* height: 140px; */
    }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
    .login_container {
        width: 884px;
        padding: 0 2rem;
    }
    .login_wrapper {
        width: 50vw;
        padding: 0 2rem;
    }
    .login_login_form__icon {
        width: 1.6rem;
        height: 1.6rem;
    }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
    :root {
        --font-login-lg: 1.8rem; 
        --font-login-md: 1.6rem; 
        --font-login-sm: 1.4rem; 
        --font-login-xs: 1.4rem; 
    }
    .login_container {
        width: 600px;
    }
    .login_wrapper {
        width: 100vw;
        padding: 0 4rem;
    }
    .login_logo {
        margin-bottom: 6rem;
    }
    .login_login_form__icon {
        width: 1.4rem;
        height: 1.4rem;
    }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
    .login_container {
        width: 420px;
        padding: 0 .5rem;
    }
}
@media only screen and (max-width: 420px) {
    .login_container {
        width: 380px;
        padding: 0 .5rem;
    }
}
