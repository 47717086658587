/* CUSTOM VARIABLES */
:root {
    --font-lg: 2rem;
    --font-md: 1.8rem;
    --font-sm: 1.7rem;
    --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.alln_container {
    width: 1270px;
    display: flex;
    justify-content: center;
    margin: 0rem auto;
    gap: 3rem;
    /* padding: 0 1rem; */
    margin-top: 4rem;
}

.alln_main {
    flex: 3.5;
}

/* END OF GLOBAL STYLE */

.alln_main__title {
    color: var(--color-primary-red);
}

.alln_main__date {
    font-size: var(--font-sm);
}

.alln_side__logo {
    margin-top: 3rem;
    margin-right: 8vw;
}


/* table style */

.alln_table table {
    width: 100%;
    text-align: right;
    border-collapse: collapse;
}

.alln_table table tr {
    border: 1px solid #eeeeee;
}

.alln_table table td:first-child svg {
    width: 3.2rem;
}
.alln_table table td:first-child {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.alln_table table td,
.alln_table table th {
    padding-right: 1.5rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
}

.alln_table tbody td {
    font-size: var(--font-sm);
}


.alln_table table tbody tr:nth-child(odd) {
    background: #f9f9f9;
}

.alln_table table tbody tr:nth-child(even) {
    background: #fff;
}

.alln_td_span_time {
    font-size: 1.4rem;
}
.alln_td_span_date {
    font-weight: bold;
}
/* end table style */



.alln_middle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
    min-height: calc(100% - 218px);
}

.alln_middle__col-right {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 3;
    position: relative;
    min-height: calc(100% - 218px);
    background-color: #fff;
}

.alln_middle__col-left {
    flex: 1;
}



/******************************/
/* utils */
.alln_fill_path {
    fill: white;
}

.show_block {
    display: block;
}

.show_flex {
    display: flex;
}

.rotate_svg {
    transform: rotate(180deg);
}

.alln_greyed_out {
    background-color: var(--color-bkg) !important;
}

/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {

    /* CUSTOM VARIABLES */
    /* smaller font sizes */
    :root {
        --font-lg: 1.8rem;
        --font-md: 1.7rem;
        --font-sm: 1.6rem;
        --font-xs: 1.5rem;
    }

    /* END OF CUSTOM VARIABLES */
    .alln_container {
        width: 1140px;
        margin-top: 4rem;
    }

    /* size of svg icons in side menu */
    .alln_menu_svg {
        width: 2.7rem;
    }

    /* height of list items in side menu */
    /* .side li a {
        height: 3.8rem;
        margin-bottom: .8rem;
    } */

    .alln_side__logo {
        margin-right: 3vw;
    }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
    .alln_container {
        width: 1000px;
    }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
    .alln_container {
        width: 820px;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
        margin-top: 8rem;
    }

    .alln_middle__col-left {
        display: none;
    }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
    .alln_container {
        width: 600px;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
    }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
    .alln_container {
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 6rem;
    }
}