/* CUSTOM VARIABLES */
:root {
  --font-lg: 2rem;
  --font-md: 1.8rem;
  --font-sm: 1.7rem;
  --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.params_container {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  gap: 3rem;
  /* padding: 0 1rem; */
  margin-top: 4rem;
}

.params_main {
  flex: 3.5;
}

/* END OF GLOBAL STYLE */

/* table style */

/* end table style */

.params_middle {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
}

/* calendar style */

/* end of calendar style */

/* table ctonrols style */
/* .table_controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
} */

.params_add_button svg {
  fill: #ffffff;
  margin-left: 1rem;
  transform: translateY(12.5%);
}

.params_add_button {
  font-size: var(--font-xs);
  color: #ffffff;
  background: #ca2946;
  border: none;
  padding: 0 1.5rem;
  border-radius: 0.5rem;
  /* width: 4rem; */
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'beIN Normal';
  min-height: 3.6rem !important;
}

.params_add_button:hover {
  opacity: 0.8;
}

/* end of table controls */

/* new session style */
.params_newType {
  background: white;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 6rem;
  padding-bottom: 2rem;
  display: none;
}

.params_form__sections {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  gap: 7rem;
  font-size: var(--fonts-md);
}

.params_form__btn_grp {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-left: 2rem;
  margin-top: 5rem;
}

.params_form__btn_grp button {
  border: none;
  padding: 0 1.8rem;
  font-size: var(--font-xs);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'beIN Normal';
}

.params_form__btn_grp button {
  display: block;
  /* float: left; */
  margin-left: 1rem;
}

.params_form__btn_grp .tickSpan {
  transform: translateY(20%);
  height: 15px;
  margin-left: 0.5rem;
}
.params_ltSpan {
  /* font-size: var(--font-lg); */
  margin-left: 1.5rem;
}

.params_form__btn_grp button:last-child {
  color: white;
  background: #63b17d;
}

.params_form__sections-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.3;
  gap: 1rem;
  font-size: var(--font-xs);
}

.params_form__sections-right div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.params_form__sections-right input,
.params_form__sections-right select {
  width: 70%;
  height: 2.8rem;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
}

.params_form__sections-right input {
  padding: 1rem;
}

.params_form__sections-left {
  flex: 1;
  font-size: var(--font-xs);
}

.params_form__sections-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.params_form__label {
  margin-left: 4rem;
}

.params_form__sections-left svg {
  transform: translateY(25%);
}

.params_form__sections label,
.params_form__sections-left div:first-child {
  color: #891423;
}

.params_form__date_selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.1rem 1rem;
  /* transform: scale(0); */
}

.params_calendar_icon {
  height: 25px;
  cursor: pointer;
}

/* end of new session style */

/* popup style */

.popup {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transition: 200ms ease-in-out;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 1.4rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  padding: 1rem;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
  z-index: 1001;
}

.popup__title {
  align-self: center;
  font-size: var(--font-md);
}

.popup__item {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  font-size: var(--font-xs);
  padding: 0 2rem;
}

.popup__btn_grp button {
  border: none;
  padding: 0 0.8rem;
  font-size: var(--font-xs);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'beIN Normal';
  min-height: 4rem;
}

.popup__btn_grp button span {
  display: block;
  float: left;
  margin-left: 1rem;
}

.popup__btn_grp .trashSpan {
  transform: translateY(10%);
}

.popup__btn_grp .trashSpan svg {
  height: 20px;
}

.popup__btn_grp button:last-child {
  color: white;
  background: #ca2946;
}

.popup__court {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.popup__court svg {
  width: 1rem;
}

.popup.active {
  transform: translate(-50%, -50%) scale(1);
}

/* end of popup style */

/* overlay style */

/* end of overlay style */

/* calendar page-specific style */

/* end of calendar style */

/* params content style */
.params_middle__wrapper {
  display: block;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}
.params_col-right {
  flex: 1;
  display: block;
  margin-bottom: 2%;
  position: fixed;
  height: 100%;
  width: 15%;
  margin: 0px;
  margin-top: 2px;
  border-radius: 20px;
}

.params_cards {
  display: block;
  align-items: center;
  flex-direction: initial;
  gap: 1rem;
  flex: 1;
}

.params_card {
  margin-bottom: 5%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 0.7rem;
  font-size: var(--font-xs);
}

.params_card__text {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}
.params_card__pic {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  background: #ca2946;
  border-radius: 50%;
  color: white;
  position: relative;
}
.params_card__pic div {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 2rem;
  font-weight: bold;
}

.params_col-left {
  flex: 3.5;
  display: block;
  gap: 0.5rem;
  align-items: start;
  float: left;
  width: 82%;
}

.params_table_section {
  width: 85%;
  /*float: left;*/
  overflow: auto;
  padding: 15px;
  display: flex;
  flex-flow: row wrap;
  /* margin-right: 30%;*/
  background-color: rgb(238, 238, 238);
  gap: 1rem;
  flex-direction: row;
  flex: 3;
}

.params_user_details {
  width: 85%;
  float: left;
  overflow: auto;
  padding: 15px;
  display: flex;
  flex-flow: row wrap;
  margin-right: 20%;
  background-color: rgb(238, 238, 238);
  flex: 3.5;
  flex-direction: column;
  gap: 1rem;
  /*overflow: auto;
  height: 550px;*/
}
.params_user_details {
  background-color: white;
  min-height: 20rem;
  border-radius: 10px;
  float: left;
  width: 82%;
}

.params_basic_details {
  border-radius: 10px;
  display: flex;
}

.params_user_details_gap {
  height: 1rem;
  background: #eeeeee;
}

.params_more_details,
.params_basic_feilds_section {
  border-radius: 10px;
  padding: 2rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: var(--font-xs);
  flex: 1.5;
}

.params_basic_feilds_section label {
  /* color: var(--color-text-secondary); */
}
.param_basic_details__field {
  display: flex;
}

.params_user_details label {
  color: var(--color-text-secondary);
  display: inline-block;
  min-width: 10rem;
}

.params_user_details input {
  border: 1px solid #cbcbcb;
  min-height: 2rem;
  padding: 0 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  flex: 1;
  border-radius: 0.5rem;
}
.params_user_details textarea {
  width: 100%;
  height: 8rem;
  outline: none;
  resize: none;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 0.5rem;
  padding: 1rem;
}

.params_basic_pic_section {
  display: flex;
  padding-top: 2rem;
  gap: 0.25rem;
  flex: 1;
  margin-right: 2rem;
}

.params_user_pic {
  height: 130px;
}

.params_set_user_pic {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.params_set_user_pic svg {
  height: 20px;
  cursor: pointer;
}

#params_less_width_label {
  min-width: 8rem;
}
.params_field_grp {
  justify-content: space-between;
}
.params_field_grp select {
  background: none;
  outline: none;
  border: 1px solid #cbcbcb;
  padding: 0 0.5rem;
  min-width: 18rem;
  cursor: pointer;
}
.params_field_grp {
  display: flex;
  flex-direction: row;
  gap: 3.8rem;
}

#params_postal_code {
  max-width: 18rem;
}

.judgement_types_table {
  display: none;
}
.lawsuit_types_table {
  display: none;
}

/* end of params content style */

/* table style */
.params_table {
  flex: 1.5;
}
.params_table table {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
}

.params_table table tbody tr {
  height: 4.2rem;
  border: 1px solid #eeeeee;
}

.params_table table tr {
  border: 1px solid #eeeeee;
}

.params_table table td {
  padding-right: 1.5rem;
  font-size: var(--font-sm);
}

.params_table table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.params_table table tbody tr:nth-child(even) {
  background: #fff;
}

.params_table table td:last-child {
  direction: ltr;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transform: translateX(2rem);
}
.params_table table td:last-child svg {
  cursor: pointer;
  transform: translateY(25%);
  height: 20px;
}

.params_table_section__btn_grp {
  flex: 2;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.params_table_section__btn_grp {
  /* margin-top: 4.3rem; */
}

/* end of table style */

/******************************/
/* utils */
.params_fill_path {
  fill: white;
}

.show_block {
  display: block;
}

.show_flex {
  display: flex;
}

.rotate_svg {
  transform: rotate(180deg);
}

.hide_scale {
  transform: scale(0);
}

.params_highlight {
  background: #ca2946;
  color: white;
}
.hide {
  display: none !important;
}

.active_svg text,
.active_svg polygon,
.active_svg polyline,
.active_svg line,
.active_svg polygon,
.active_svg rect,
.active_svg circle,
.active_svg ellipse,
.active_svg svg,
.active_svg path {
  fill: white;
}

/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
  /* CUSTOM VARIABLES */
  /* smaller font sizes */
  :root {
    --font-lg: 1.8rem;
    --font-md: 1.7rem;
    --font-sm: 1.6rem;
    --font-xs: 1.5rem;
  }

  /* END OF CUSTOM VARIABLES */
  .params_container {
    width: 1140px;
    margin-top: 4rem;
  }

  /* size of svg icons in side menu */
  .params_menu_svg {
    width: 2.7rem;
  }

  /* height of list items in side menu */
  .params_container li a {
    height: 3.8rem;
    margin-bottom: 0.8rem;
  }

  .params_side__logo {
    margin-right: 3vw;
  }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
  .params_container {
    width: 1000px;
  }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
  .params_container {
    width: 884px;
    padding: 0 2rem;
  }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
  .params_container {
    width: 720px;
    padding: 0 2rem;
  }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
  .params_container {
    width: 580px;
    padding: 0 2rem;
  }
}
