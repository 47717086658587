/* CUSTOM VARIABLES */
:root {
  --font-lg: 2rem;
  --font-md: 1.8rem;
  --font-sm: 1.7rem;
  --font-xs: 1.6rem;
}

/* END OF CUSTOM VARIABLES */

/* GLOBAL STYLE */
.clients_container {
  width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0rem auto;
  gap: 3rem;
  /* padding: 0 1rem; */
  margin-top: 4rem;
}

.clients_main {
  flex: 3.5;
}

/* END OF GLOBAL STYLE */

.clients_top_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}

.clients_top_bar__el-right,
.clients_top_bar__identifier,
.clients_top_bar__el-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.clients_top_bar__el-right {
  font-size: var(--font-lg);
}

.clients_top_bar__el-left {
  font-size: var(--font-xs);
}

.clients_main__title {
  color: var(--color-primary-red);
}

.clients_main__date {
  font-size: var(--font-sm);
}

.clients_side__logo {
  margin-top: 3rem;
  margin-right: 8vw;
}

.clients_top_bar__identifier {
  gap: 0.5rem;
}

.clients_top_bar__pic {
  width: 2.8rem;
  height: 2.8rem;
  background: var(--color-secondary-red);
  border-radius: 50%;
  color: white;
  position: relative;
}

.clients_top_bar__user_initial {
  position: absolute;
  line-height: 0;
  top: 1.4rem;
  left: 1.4rem;
  transform: translate(-50%);
  font-size: 2rem;
}

/* table style */

/* end table style */

.clients_middle {
  display: flex;
  flex-direction: column;
  width: 99%;
  justify-content: flex-start;
  gap: 2rem;
}

/* calendar style */

/* end of calendar style */

/* table ctonrols style */
/* .table_controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
} */

.clients_add_button svg {
  fill: #ffffff;
  margin-left: 2rem;
}

.clients_add_button {
  font-size: 1.6rem;
  color: #ffffff;
  background: #ca2946;
  border: none;
  padding: 0.1rem 1.5rem;
  border-radius: 0.8rem;
  /* width: 4rem; */
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'beIN Normal';
  min-height: 4rem !important;
}

.clients_add_button:hover {
  opacity: 0.8;
}

/* end of table controls */

/* new session style */
.clients_newClient {
  background: white;
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 6rem;
  padding-bottom: 2rem;
  display: none;
}

.clients_form__sections {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 7rem;
  font-size: var(--fonts-md);
}

.clients_form__btn_grp {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-left: 2rem;
  margin-top: 5rem;
}

.clients_form__btn_grp button {
  border: none;
  padding: 0 1.8rem;
  font-size: var(--font-xs);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'beIN Normal';
}

.clients_form__btn_grp button {
  display: block;
  /* float: left; */
  margin-left: 1rem;
}

.clients_form__btn_grp .tickSpan {
  transform: translateY(20%);
  height: 15px;
  margin-left: 0.5rem;
}
.clients_ltSpan {
  /* font-size: var(--font-lg); */
  margin-left: 1.5rem;
}

.clients_form__btn_grp button:last-child {
  color: white;
  background: #63b17d;
}

.clients_form__sections-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.3;
  gap: 1rem;
  font-size: var(--font-xs);
}

.clients_form__sections-right div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.clients_form__sections-right input,
.clients_form__sections-right select {
  width: 70%;
  height: 2.8rem;
  border: 0.1rem solid #cbcbcb;
  background: none;
  font-size: var(--font-xs);
  border-radius: 0.5rem;
}

.clients_form__sections-right input {
  padding: 1rem;
}

.clients_form__sections-left {
  flex: 1;
  font-size: var(--font-xs);
}

.clients_form__sections-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.clients_form__label {
  margin-left: 4rem;
}

.clients_form__sections-left svg {
  transform: translateY(25%);
}

.clients_form__sections label,
.clients_form__sections-left div:first-child {
  color: #891423;
}

.clients_form__date_selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.1rem 1rem;
  /* transform: scale(0); */
}

.clients_calendar_icon {
  height: 25px;
  cursor: pointer;
}

/* end of new session style */

/* popup style */

/* end of popup style */

/* overlay style */

/* end of overlay style */

/* calendar page-specific style */

/* end of calendar style */

/* clients content style */
.clients_middle__wrapper {
  display: flex;
  width: 99%;
  justify-content: space-between;
  gap: 1rem;
}
.clients_col-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.clients_right_search input {
  width: 100%;
  height: 3.5rem;
  padding: 0 3.2rem;
  font-size: 1.7rem;
  font-family: 'beIN Normal';
  border-radius: 0.7rem;
}
.clients_right_search {
  width: 100%;
  position: relative;
  height: 3.5rem;
}
/* FIX THIS */
.clients_right_search img {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-55%);
  z-index: 10;
}

.clients_cards {
  display: flex;
  align-items: center;
}

.clients_card {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  background: #f6f6f6;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  font-size: 1.5rem;
}
.clients_card__name {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.clients_card__pic {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  background: #ca2946;
  border-radius: 50%;
  color: white;
  position: relative;
}
.clients_card__pic div {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 2rem;
  font-weight: bold;
}

/* hide the left arrow for non-selected cards */
.clients_card svg {
  display: none;
}
/* sho left arr and change boackground for selected card */
.selectedCard {
  background-color: #ffffff;
}
.selectedCard svg {
  display: block;
}

.clients_col-left {
  flex: 3.5;
}

.clients_details_section {
  background: white;
  border-radius: 10px;
  font-size: 1.4rem;
  padding-right: 4rem;
  padding-bottom: 1rem;
  width: 100%;
}
.clients_details_section:hover .clients_details_section__head svg {
  display: block;
}

.clients_details_section__head {
  /* border-bottom: 1px solid #EEEEEE; */
  /* height: 3rem; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 4rem;
}
.clients_details_section__head::after {
  content: '';
  position: absolute;
  width: 71%;
  height: 0.1rem;
  background: #eeeeee;
  right: 0;
  bottom: 0.1rem;
  transform: translateX(4rem);
}
.clients_details_section__head svg {
  height: 20px;
  cursor: pointer;
  display: none;
}

.clients_details_section__body,
.clients_details_section__field {
  display: flex;
}
.clients_details_section__body {
  /* margin-top: .5rem; */
  flex-direction: column;
}

.clients_details_section__field {
  padding: 0.5rem 0;
  gap: 5rem;
}

.clients_field_title {
  color: #8c8c8c;
  min-width: 8rem;
}

.clients_table_section {
  width: 100%;
  margin-top: 5rem;
  margin-right: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: row;
}

/* end of clients content style */

/* table style */
.clients_table {
  flex: 1.5;
}
.clients_table table {
  width: 100%;
  text-align: right;
  border-collapse: collapse;
}

.clients_table table thead tr {
  height: 4.2rem;
}
.clients_table table tbody tr {
  height: 4.2rem;
  border: 1px solid #eeeeee;
}
.clients_table table tfoot tr {
  height: 3.8rem;
}
.clients_table table tr {
  border: 1px solid #eeeeee;
}

.clients_table table td,
.clients_table table th {
  padding-right: 1.5rem;
}

.clients_table table td:nth-child(3),
.clients_table table th:nth-child(3) {
  text-align: center;
}

.clients_table thead {
  color: var(--color-primary-red);
  font-size: var(--font-xs);
}

.clients_table tfoot td,
.clients_table tbody td {
  font-size: var(--font-sm);
}

.clients_table table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.clients_table table tbody tr:nth-child(even) {
  background: #fff;
}

.clients_table table tfoot {
  text-align: center;
  background: #f9f9f9;
}

.clients_table__footer {
  color: var(--color-primary-red);
  cursor: pointer;
  font-size: var(--font-sm);
}

.clients_table__footer svg {
  margin-right: 2rem;
}

.clients_table table tbody td:nth-child(3) {
  padding-right: 2rem;
}

.clients_table_section__btn_grp {
  flex: 1;
}

.clients_table_section__btn_grp {
  margin-top: 4.3rem;
}

.table_location_pin_sm {
  position: absolute;
  transform: translate(1.8rem, 1.1rem);
}
/* end of table style */

/******************************/
/* utils */
.clients_fill_path {
  fill: white;
}

.show_block {
  display: block;
}

.show_flex {
  display: flex;
}

.rotate_svg {
  transform: rotate(180deg);
}

.hide_scale {
  transform: scale(0);
}

/* end of utils */
/******************************/
/* Extra Large screen */
@media only screen and (max-width: 1920px) {
  /* CUSTOM VARIABLES */
  /* smaller font sizes */
  :root {
    --font-lg: 1.8rem;
    --font-md: 1.7rem;
    --font-sm: 1.6rem;
    --font-xs: 1.5rem;
  }

  /* END OF CUSTOM VARIABLES */
  .clients_container {
    width: 1140px;
    margin-top: 4rem;
  }

  /* size of svg icons in side menu */
  .clients_menu_svg {
    width: 2.7rem;
  }

  /* height of list items in side menu */
  .clients_container li a {
    height: 3.8rem;
    margin-bottom: 0.8rem;
  }

  .clients_side__logo {
    margin-right: 3vw;
  }
}

/******************************/
/* Large screen */
@media only screen and (max-width: 1200px) {
  .clients_container {
    width: 1000px;
  }
}

/******************************/
/* Medium screen */
@media only screen and (max-width: 1024px) {
  .clients_container {
    width: 820px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
    margin-top: 9rem;
  }
}

/******************************/
/* Small */
@media only screen and (max-width: 800px) {
  .clients_container {
    width: 600px;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
}

/******************************/
/* XS */
@media only screen and (max-width: 600px) {
  .clients_container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }
}
