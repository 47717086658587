* {
  margin: 0;
  padding: 0;
}
*:focus {
  outline: none !important;
  border: none !important;
}

body {
  unicode-bidi: bidi-override !important;
  direction: unset !important;
  text-align: right;
}

.lower-cut-overlay-layer {
  width: 100%;
}
.header-section .lower-cut-overlay-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 50;
}

.header-section {
  height: 650px;
  width: 100%;
  background-size: cover;
  position: relative;
}

.header-section-banner-overlay {
  background: rgb(189, 35, 60);
  height: 650px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.7;
  z-index: 3;
}

.header-section-content {
  height: 650px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.header-section-content-Nav-container {
  margin: 0px 60px;
}

.header-section-content-body-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.header-section-content-body-content-searchbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}

.header-section-content-Nav-content,
.header-section-content-Nav-content-right-side {
  display: flex;
  align-items: center;
  gap: 30px;
}
.header-section-content-Nav-content {
  justify-content: space-between;
  padding: 20px 0px;
}
.platform-logo-placeholder-mobile {
  display: none;
}
.platform-logo-placeholder {
  display: flex;
  align-items: center;
  gap: 10px;
}
.platform-logo-placeholder span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 37px;
  color: #ffffff;
}

.nav-menu-content {
  display: flex;
  align-items: center;
  gap: 25px;
}
.nav-menu-content a {
  text-decoration: none;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}
.sign-up-btn-container {
  display: none;
}
.header-section-content-Nav-content .sign-up-btn-container button {
  padding: 10px 30px;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border: 2px solid #ffffff;
  border-radius: 8px;
}

.header-section-content-body-content-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
/*
.header-section-content-body-content-title-container{
display:none;
}
*/

.header-section-content-body-content-title span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  color: #ffffff;
}

/*
.header-section-content-body-content-desc{
    display: none;
}*/

.header-section-content-body-content-desc span {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.header-section-content-body-content-searchbar {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  background: white;
  padding: 8px;
  border-radius: 8px;
  gap: 10px;
}
/*
.header-section-content-body-content-searchbar{
    width: auto;
}
*/

.header-section-content-body-content-searchbar-input-field {
  display: flex;
  align-items: center;
  gap: 17px;
  padding-right: 10px;
  width: 100%;
}
.header-section-content-body-content-searchbar-input-field input {
  border: none;
  width: 100%;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  color: #959ead;
  width: 100%;
}
.header-section-content-body-content-searchbar button {
  padding: 10px 40px;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

/*
.header-section-content-body-content-searchbar-desc{
    display: none;
}*/

.header-section-content-body-content-searchbar-desc span {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.body-subsection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.available-courses-container {
  background: #fafafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;
}

.body-subsection-content-header {
  margin: 40px 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/*
.body-subsection-content-header{
    margin: 30px;
    display: flex;
    flex-direction: column;
    gap:10px;
}
*/

.body-subsection-content-title,
.body-subsection-content-desc {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.body-subsection-content-title span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  color: #171725;
}
.body-subsection-content-desc span {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #5a7184;
  text-align: center;
  line-height: 32px;
}

.membership-info-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.membership-info-elem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: auto;
  align-items: center;
}
.membership-info-elem-icon-placeholder {
  background: rgba(0, 115, 255, 0.06);
  border-radius: 16px;
  padding: 12px;
  display: inline-flex;
}
/*.membership-info-elem-icon-placeholder svg{
    border: 1px #44D5E9 dashed;
}*/
.membership-info-elem-title span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #183b56;
}
.membership-info-elem-desc {
  display: table;
}
.membership-info-elem-desc span {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #5a7184;
  display: table-row;
}
.membership-info-elem-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}
.membership-info-elem-btn:hover {
  cursor: pointer;
}
.membership-info-elem-btn span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #3dd598;
}

.available-courses-content-elem {
  position: relative;
  height: 314px;
}

/*
.course-miniature img{
    width: 300px;
    height:235px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
}*/

.course-title-container {
  width: 100%;
  position: absolute;
  bottom: 30px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-title-content {
  width: 95%;
  height: 80px;
  background: white;
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding-right: 30px;
  padding-left: 10px;
}
.course-title-content span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: right;
  color: #171725;
}
.available-courses-content {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.show-more-courses-btn-container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-more-courses-btn-container button {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #5a7184;
  /*padding:7px 115px;*/
  padding: 7px 0px;
  width: 80%;
  border: 2px solid #b5b5be;
  border-radius: 8px;
}
/*
.show-more-courses-btn-container{
    width:100%;
}
.show-more-courses-btn-container button{
    font-family: Cairo;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #5A7184;
    width: 100%;
    border: 2px solid #B5B5BE;
    border-radius: 8px;
}*/

.body-subsection-container {
  position: relative;
}

.available-courses-section {
  margin-top: 50px;
  /*padding-top: 90px;
    padding-bottom: 50px;*/
  position: relative;
  background: #fafafb;
}
.available-courses-container {
  padding-top: 50px;
}

.available-courses-section .upper-cut-overlay-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 175px;
}

.footer-section {
  background: rgb(189, 35, 60);
  padding-top: 70px;
}
.news-letter-section-container {
  width: 100%;
}
.news-letter-section-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-letter-section-text-container span {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: right;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-align: center;
}
.footer-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-section-content {
  width: 80%;
}

.news-letter-section-input-field {
  display: flex;
  align-items: center;
  gap: 17px;
  width: 100%;
}

.news-letter-section-input-container form input {
  border: none;
  width: 100%;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  color: #959ead;
  height: 45px;
  padding-right: 10px;
  border-radius: 8px;
}

.news-letter-section-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  justify-content: center;
}

.news-letter-section-text-container span {
  text-align: center;
  line-height: 1.5em;
}

.news-letter-section-input-container form button {
  height: 45px;
  padding: 0px 36px;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  text-align: right;
  color: #ffffff;
}
.news-letter-section-input-container form {
  display: flex;
  align-items: center;
  gap: 13px;
  flex-direction: row;
}
.news-letter-section-input-container-desc span {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  color: #5a7184;
}
.news-letter-section-input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.extra-nav-social-media-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
}
.social-media-icon-container a,
.social-media-icon-container a svg {
  transition: 0.6s;
}
.social-media-icon-container a:hover {
  opacity: 0.8;
}
.social-media-icon-container a svg:hover > path {
  fill: white;
}

.social-media-desc-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 30px;
}
.social-media-desc-container .extra-desc span {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #92929d;
}

a {
  text-decoration: none;
}
.extra-nav-col-title a:hover {
  text-decoration: none;
}
.extra-nav-col-title a {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  /*font-size: 18px;*/
  font-size: 20px;
  line-height: 34px;
  text-align: right;
  color: #5a7184;
}
.extra-nav {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.extra-nav-col-sub-elem,
.extra-nav-col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.extra-nav-col {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.social-media-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
}
.extra-nav-col-sub-elem a {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: right;
  color: #959ead;
  text-align: center;
}
.social-media-desc-container,
.extra-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-section-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.copyright-text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.copyright-text-container span {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #5a7184;
}
.body-subsection-content-header {
  margin-top: 60px;
}

/* custom sandwich menu btn */

.menu-sandwich-btn-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.menu-sandwich-btn-and-search-icon {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.menu-sandwich-btn-bar-prim,
.menu-sandwich-btn-bar-sec {
  width: 22px;
  height: 3px;
  border-radius: 10px;
  background: white;
}
.menu-sandwich-btn-bar-tir {
  width: 11px;
  height: 3px;
  border-radius: 10px;
  background: white;
}
/*end*/

.body-subsection-content-AC {
  margin: 50px calc((800px / 4) - 120px) 80px calc((800px / 4) - 120px);
}
.available-courses-container .body-subsection-content-AC {
  margin: 0;
}
.last-pos-social-media-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.last-pos-social-media-icons-container .social-media-icon-container {
  display: flex;
  gap: 24px;
}

.menu-sandwich-btn-and-search-icon {
  width: 100%;
}
.nav-menu-container {
  display: none;
}
.search-bar-container,
.search-bar-content {
  width: 100%;
}
.search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar-content {
  background: white;
  padding: 8px 15px;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.search-bar-content input {
  border: none;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  color: #959ead;
}

.body-section .body-subsection-content-AC {
  margin: 0px;
}

.membership-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.membership-info-content {
  width: 80%;
  padding: 0px 20px;
}

.header-section-content-body-content-title-container {
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  gap: 20px;
}
.header-section-content-Nav-content .platform-logo-placeholder span {
  display: none;
}

.header-section-content-body-content-searchbar-container {
  display: none;
}
.available-courses-content-elem {
  width: 80%;
}
.course-miniature img {
  width: 100%;
  height: 235px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}
.news-letter-section-input-container form button {
  display: none;
}

@media screen and (max-width: 600px) {
  /*.header-section-content-Nav-content-right-side .platform-logo-placeholder span{
        display:none !important;
    }*/
  .header-section-content-Nav-content {
    justify-content: space-between;
    padding: 20px 0px;
    align-items: center;
  }
  .header-section-content-Nav-content {
    gap: 10px;
  }
  .header-section-content-Nav-container {
    margin: 0px 40px;
  }
  /*
    .header-section-content-body-content-searchbar-container{
        display:none;
    }*/
  .header-section-content-body-content-title span {
    font-size: 60px;
  }
  .header-section-content-body-content-desc span {
    font-size: 18px;
  }
}

@media screen and (min-width: 500px) {
  .search-bar-container {
    display: none;
  }
  .menu-sandwich-btn-and-search-icon {
    width: auto;
  }
  .header-section-content-body-content-title span {
    font-size: 38px;
  }
  .header-section-content-body-content-desc span {
    font-size: 16px;
  }
  .header-section-content-body-content-searchbar-container {
    display: flex;
  }
  .header-section-content-body-content-title-container {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .header-section-content-Nav-content .platform-logo-placeholder span {
    display: block !important;
  }
}

@media screen and (min-width: 533px) {
  .extra-nav-col {
    width: auto;
  }
  .extra-nav {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 600px) {
  .available-courses-content-elem {
    width: 43%;
  }
  .available-courses-content {
    gap: 20px;
  }
  .news-letter-section-input-container form button {
    display: block;
  }
  .show-more-courses-btn-container button {
    padding: 7px 115px;
    width: auto;
  }
}

@media screen and (min-width: 640px) {
  .membership-info-container,
  .membership-info-content {
    width: 100%;
  }
  .membership-info-elem {
    width: 45%;
  }
  .body-subsection-content {
    gap: 20px;
  }
  .extra-nav {
    justify-content: center;
  }
  .extra-nav-col {
    width: 45%;
  }
}
@media screen and (min-width: 700px) {
  .extra-nav {
    flex-wrap: nowrap;
  }
  .social-media-icon-container {
    justify-content: unset;
    gap: 40px;
  }
}
@media screen and (min-width: 950px) {
  .social-media-desc-container {
    width: 30%;
  }
  .extra-nav-social-media-container {
    flex-direction: row;
  }
  .extra-nav {
    width: 100%;
    justify-content: space-between;
    gap: 20px;
  }
  .social-media-desc-container {
    gap: 0px !important;
  }

  .extra-nav-col-title a {
    font-size: 18px;
  }
  .extra-nav-col-title a {
    font-size: 18px;
  }
  .extra-nav-col-sub-elem a {
    font-size: 16px;
  }
}

@media screen and (min-width: 1000px) {
  .news-letter-section-content {
    flex-direction: row;
  }
  .news-letter-section-text-container span {
    text-align: right;
  }
  .footer-section-content {
    gap: 60px;
  }
  .available-courses-content-elem,
  .membership-info-elem {
    width: 20%;
  }
  .news-letter-section-content {
    align-items: flex-start;
  }
  .nav-menu-container {
    display: block;
  }
  .menu-sandwich-btn-and-search-icon {
    display: none;
  }
  .sign-up-btn-container {
    display: block;
  }
  .news-letter-section-input-field input {
    padding-right: 20px !important;
  }
  .extra-nav-social-media-container {
    gap: 0px;
  }
  .header-section-content-body-content-searchbar {
    width: 770px;
  }
  .available-courses-container {
    justify-content: center;
    flex-direction: column;
    padding-top: 5%;
  }
  .news-letter-section-container {
    padding-bottom: 150px;
    border-bottom: 1px solid #1a3245;
  }
  .copyright-text-container {
    margin-bottom: 150px;
    margin-top: 75px;
  }
}

@media screen and (min-width: 1300px) {
  .body-subsection-content {
    width: 100%;
  }
  .body-subsection-content-header {
    margin: 60px 0px;
    width: 90%;
  }
  .body-subsection-content .body-subsection-content-AC {
    width: 90%;
  }
  .membership-info-elem {
    width: 23%;
  }
  .available-courses-section .body-subsection-content-header {
    margin-top: 150px;
  }
  .available-courses-content {
    gap: 25px;
  }
  .social-media-desc-container {
    width: 80%;
    margin-left: 60px;
  }
  .extra-nav-col-sub-elem a {
    text-align: right;
  }
  .extra-nav-col {
    width: auto;
  }
  .header-section-content-body-content {
    height: 450px;
  }
  .footer-section-content {
    gap: 150px;
  }
  .social-media-desc-container {
    gap: 20px !important;
  }
}
@media screen and (min-width: 1440px) {
  .body-subsection-content {
    width: 1500px;
  }
  .body-subsection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .available-courses-content {
    gap: 45px;
  }
}
@media screen and (min-width: 1600px) {
  .available-courses-container {
    margin-bottom: 20px !important;
    padding-bottom: 20px !important;
  }
}

.menu-sandwich-btn-content:hover {
  cursor: pointer;
}
