@import 'home.css';
@import 'login.css';
@import 'signup.css';
@import 'jalaset.css';
@import 'rendez-vous.css';
@import 'lawsuit.css';
@import 'appeals.css';
@import 'clients.css';
@import 'reports.css';
@import 'legal-journals.css';
@import 'params.css';
@import 'jurisprudence.css';
@import 'messenger.css';
@import 'archives.css';
@import 'all-notifications.css';
/*@import 'question/animate.css';
@import 'question/flatpickr.min.css';
@import 'question/style.css';
@import 'question/responsive.css';
@import 'question/line-awesome-font-awesome.min.css';
@import 'question/line-awesome-font-awesome.css';
@import 'question/font-awesome.min.css';
@import 'question/line-awesome.css';
@import 'editor.css';*/

@font-face {
  font-family: 'beIN Normal';
  src: url('../fonts/beIN/beIN_Normal.ttf');
}

/* CUSTOM VARIABLES */
:root {
  --gradient-primary-toleft: linear-gradient(to left, #540d16, rgb(189, 35, 60));
  --gradient-primary-toright: linear-gradient(to right, #540d16, rgb(189, 35, 60));

  --color-bkg: #eeeeee;
  --color-primary-red: #a8192b;
  --color-secondary-red: #820f1e;
  --color-text-primary: #2d2d2d;
  --color-text-secondary: #878787;

  /* utils */
  --url-link-pin: url('../img/location-pin.svg');
}

/* END CUSTOME VARIABLES */

/* RESETS */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* 10px = 62.5% of default browers font-size (16px) */
  direction: rtl;
  background: var(--color-bkg);
  color: var(--color-text-primary);
  font-family: 'beIN Normal';
  /* overflow: hidden;*/
}

a {
  text-decoration: none;
  color: var(--color-black);
}

a:visited {
  text-decoration: none;
}

input {
  outline: none;
  border: none;
}

li {
  list-style: none;
}

button {
  cursor: pointer;
  min-height: 3.9rem;
}

/* END OF RESETS */
