//
// pricing.scss
//

.pricing-box {
  box-shadow: $box-shadow;
  padding: 35px 50px;
  border-radius: 20px;
  position: relative;
  .plan {
    font-size: 34px;
  }
}

.pricing-badge {
  position: absolute;
  top: 0;
  /*z-index: 999;*/
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
  .badge {
    float: left;
    transform: rotate(315deg);
    left: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: $white;
    background: rgb(189, 35, 60);
  }
}
